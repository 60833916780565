import { post, get, getToken, postFormData } from './request'

export const UpdateName = (p) => post('/api-web/user/edit-name', p)

export const getPhoneCode = (p) => get('/api-web/user/phone-old-sms', p)

export const phoneCodeCheck = (p) => post('/api-web/user/phone-old-check', p)

export const getNewPhoneCode = (p) => get('/api-web/user/phone-new-sms', p)

export const bindNewPhone = (p) => post('/api-web/user/phone-new-bind', p)

export const getCountryList = (p) => get('/api-web/comm/country-zone-main', p)

export const getRegionList = (p) => post('/api-web/comm/country-zone-sub', p)

export const UpdateDate = (p) => post('/api-web/user/edit-country-zone', p)

export const UpdateImgLogo = (p) => post('/api-web/user/edit-img-logo', p)

export const apiStripPay = (p) => post('/api/pay-stripe/pay-unify', p)

export const apiGoogleLogin = (p) => post('/api-web/login/by-google-web', p)

export const apiSetAutoPlay = (p) => post('/api-web/user/edit-is-auto-play', p)

export const apiGetHelper = (p) => get('/api-web/website/setup-info', p)
