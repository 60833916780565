import moment from 'moment'

moment.locale('zh-cn')

const timeStrDigits = (value, count = 2) => {
  return `00000000${value}`.slice(-1 * count)
}

export const timeNow = (val) => {
  return moment(val).fromNow()
}

export const timeFormat = (val, format = 'YYYY-MM-DD') => {
  return moment(val).format(format)
}

export const timeHours = (time = 0) => {
  const hours =
    Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) || 0
  const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60)) || 0
  const seconds = Math.floor((time % (1000 * 60)) / 1000) || 0

  return `${hours}时 ${minutes}分 ${seconds}秒`
}

export const timeLen = (time = 0) => {
  const hours =
    Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) || 0
  const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60)) || 0
  const seconds = Math.floor((time % (1000 * 60)) / 1000) || 0

  return `${timeStrDigits(hours)}:${timeStrDigits(minutes)}:${timeStrDigits(
    seconds
  )}`
}

export const toDayZeroTime = () => {
  return moment()
    .hours(0)
    .minutes(0)
    .seconds(0)
    .milliseconds(0)
    .valueOf()
}
