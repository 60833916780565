import Vue from 'vue'
import VueRouter from 'vue-router'
/**
 * 重写路由的push方法
 */
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/Main',
    name: 'Main',
    component: () => import('../views/Main/Main.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/home'),
        meta: {
          full: true
        }
      },
      {
        path: '/userInfo',
        name: 'userInfo',
        component: () => import('../views/userInfo/userInfo')
      },
      {
        path: '/updatePhoneNumber',
        name: 'updatePhoneNumber',
        component: () => import('../views/userInfo/updatePhoneNumber')
      },
      {
        path: '/updatePsw',
        name: 'updatePsw',
        component: () => import('../views/userInfo/updatePsw')
      },

      {
        path: '/vip',
        name: 'vip',
        component: () => import('../views/vip-new/index')
      },
      {
        path: '/vip-record',
        name: 'vipRecord',
        component: () => import('../views/vip-new/record')
      },
      {
        path: '/ExerciseBook',
        name: 'ExerciseBook',
        component: () => import('../views/ExerciseBook.vue')
      },
      {
        path: '/ExerciseBookDetail',
        name: 'ExerciseBookDetail',
        component: () => import('../views/ExerciseBookDetail.vue')
      },
      {
        path: '/exercise/class',
        name: 'exerciseClass',
        component: () => import('@/views/exerciseClass')
      },
      {
        path: '/word',
        name: 'word',
        component: () => import('../views/word.vue')
      },
      {
        path: '/word-book',
        name: 'wordBook',
        component: () => import('../views/wordBook/index.vue')
      },
      {
        path: '/word-list/:id',
        name: 'wordList',
        component: () => import('../views/wordBook/list.vue')
      },
      {
        path: '/word-learn/:id',
        name: 'wordLearn',
        component: () => import('../views/wordBook/learn.vue')
      },
      {
        path: '/forum',
        name: 'forum',
        component: () => import('../views/forum/index.vue')
      },
      {
        path: '/collect',
        name: 'collect',
        component: () => import('../views/collect.vue')
      },
      {
        path: '/FileDownload',
        name: 'FileDownload',
        component: () => import('../views/FileDownload.vue')
      },
      {
        path: '/vip-old',
        name: 'vipOld',
        component: () => import('../views/VIP/VIP.vue')
      },
      {
        path: '/Search',
        name: 'Search',
        component: () => import('../views/SearchList/SearchList.vue')
      },
      {
        path: '/Message',
        name: 'Message',
        component: () => import('../views/Message/Message.vue'),
        redirect: '/InformationTeacherMessage',
        children: [
          {
            path: '/InformationTeacherMessage',
            name: 'InformationTeacherMessage',
            component: () =>
              import(
                '../views/Information/TeacherMessage/TeacherIndex/TeacherIndex.vue'
              )
          },
          {
            path: '/InformationClassMessage',
            name: 'InformationClassMessage',
            component: () =>
              import(
                '../views/Information/ClassMessage/ClassIndex/ClassIndex.vue'
              )
          },
          {
            path: '/InformationSystem',
            name: 'InformationSystem',
            component: () => import('../views/Information/System/System.vue')
          },
          {
            path: '/InformationTeacherMessageDetails',
            name: 'InformationTeacherMessageDetails',
            component: () =>
              import(
                '../views/Information/TeacherMessage/MessageDetails/MessageDetails.vue'
              )
          },
          {
            path: '/InformationClassMessageDetails',
            name: 'InformationClassMessageDetails',
            component: () =>
              import(
                '../views/Information/ClassMessage/MessageDetails/MessageDetails.vue'
                )
          },
        ]
      },
      //
      {
        path: '/GreIndex',
        name: 'GreIndex',
        component: () => import('../views/Gre/GreIndex/GreIndex.vue')
      },
      {
        path: '/GrePracticeIndex',
        name: 'GrePracticeIndex',
        component: () => import('../views/Gre/GreIndex/GreIndex.vue')
      },
      {
        path: '/GreListen',
        name: 'GreListen',
        component: () => import('../views/Gre/GreType/GreType.vue')
      },
      {
        path: '/GrePracticeListen',
        name: 'GrePracticeListen',
        component: () => import('../views/Gre/GreType/GreType.vue')
      },
      {
        path: '/GreSpeak',
        name: 'GreSpeak',
        component: () => import('../views/Gre/GreType/GreType.vue')
      },
      {
        path: '/GrePracticeSpeak',
        name: 'GrePracticeSpeak',
        component: () => import('../views/Gre/GreType/GreType.vue')
      },
      {
        path: '/GreRead',
        name: 'GreRead',
        component: () => import('../views/Gre/GreType/GreType.vue')
      },
      {
        path: '/GrePracticeRead',
        name: 'GrePracticeRead',
        component: () => import('../views/Gre/GreType/GreType.vue')
      },
      {
        path: '/GreWrite',
        name: 'GreWrite',
        component: () => import('../views/Gre/GreType/GreType.vue')
      },
      {
        path: '/GrePracticeWrite',
        name: 'GrePracticeWrite',
        component: () => import('../views/Gre/GreType/GreType.vue')
      },
      {
        path: '/GreDetail',
        name: 'GreDetail',
        component: () => import('../views/Gre/GreDetail/GreDetail.vue')
      },
      {
        path: '/GrePracticeDetail',
        name: 'GrePracticeDetail',
        component: () =>
          import('../views/Gre/GrePracticeDetail/GrePracticeDetail.vue')
      },
      {
        path: '/ExaminationList',
        name: 'ExaminationList',
        component: () =>
          import('../views/Examination/ExaminationList/ExaminationList.vue')
      },
      {
        path: '/AddExamination',
        name: 'AddExamination',
        component: () =>
          import('../views/Examination/AddExamination/AddExamination.vue')
      },
      {
        path: '/course',
        name: 'course',
        component: () => import('../views/course/SkillIndex/SkillIndex.vue'),
        meta: {
          full: true
        }
      },
      {
        path: '/course/chapter',
        name: 'courseChapter',
        component: () => import('@/views/course/SkillDetails/index.vue'),
        meta: {
          full: true
        }
      },
      {
        path: '/course/chapter/Detail',
        name: 'chapterDetail',
        component: () => import('@/views/course/chapterDetail/index.vue'),
        meta: {
          full: true,
          backgroundColor: '#4a4a58'
        }
      },
      {
        path: '/course/chapter/detail/text',
        name: 'chapterDetailText',
        component: () => import('@/views/course/textDetail/index.vue'),
        meta: {
          full: true
        }
      },
      {
        path: '/course/chapter/detail/live',
        name: 'chapterDetailLive',
        component: () => import('@/views/course/liveDetail/index.vue'),
        meta: {
          full: true
        }
      },
      {
        path: '/course/my',
        name: 'courseMyList',
        component: () => import('@/views/course/my/index.vue'),
        meta: {
          full: true
        }
      },
      {
        path: '/course/all',
        name: 'courseAllList',
        component: () => import('@/views/course/all/index.vue'),
        meta: {
          full: true
        }
      },
      {
        path: '/course/teacher/detail',
        name: 'courseTeacherDetail',
        component: () => import('@/views/course/teacherDetail/index'),
        meta: {
          full: true
        }
      },

      {
        path: '/IELTScourse',
        name: 'IELTScourse',
        component: () =>
          import('../views/IELTScourse/SkillIndex/SkillIndex.vue'),
        meta: {
          full: true
        }
      },
      {
        path: '/IELTScourse/bank',
        name: 'IELTScourseBank',
        component: () => import('../views/IELTScourse/components/bank.vue'),
        meta: {
          full: true
        }
      },
      {
        path: '/IELTScourse/cardItem',
        name: 'IELTScourseCardItem',
        component: () => import('../views/IELTScourse/components/card.vue'),
        meta: {
          full: true
        }
      },
      {
        path: '/IELTScourse/chapter',
        name: 'IELTScourseChapter',
        component: () => import('@/views/IELTScourse/SkillDetails/index.vue'),
        meta: {
          full: true
        }
      },
      {
        path: '/IELTScourse/chapter/Detail',
        name: 'IELTSchapterDetail',
        component: () => import('@/views/IELTScourse/chapterDetail/index.vue'),
        meta: {
          full: true,
          backgroundColor: '#4a4a58'
        }
      },
      {
        path: '/IELTScourse/chapter/detail/text',
        name: 'IELTSchapterDetailText',
        component: () => import('@/views/IELTScourse/textDetail/index.vue'),
        meta: {
          full: true
        }
      },
      {
        path: '/IELTScourse/chapter/detail/live',
        name: 'IELTSchapterDetailLive',
        component: () => import('@/views/IELTScourse/liveDetail/index.vue'),
        meta: {
          full: true
        }
      },
      {
        path: '/IELTScourse/my',
        name: 'IELTScourseMyList',
        component: () => import('@/views/IELTScourse/my/index.vue'),
        meta: {
          full: true
        }
      },
      {
        path: '/IELTScourse/all',
        name: 'IELTScourseAllList',
        component: () => import('@/views/IELTScourse/all/index.vue'),
        meta: {
          full: true
        }
      },
      {
        path: '/IELTScourse/teacher/detail',
        name: 'IELTScourseTeacherDetail',
        component: () => import('@/views/IELTScourse/teacherDetail/index'),
        meta: {
          full: true
        }
      },
      // {
      //   path: '/Skill/Practice/Detail',
      //   name: 'PracticeDetail',
      //   component: () =>
      //     import('../views/Skill/PracticeComponent/Detail/PracticeDetail')
      // },
      {
        path: '/Skill',
        name: 'Skill',
        component: () => import('../views/Skill/SkillIndex/SkillIndex.vue')
      },
      {
        path: '/SkillDetails',
        name: 'SkillDetails',
        component: () => import('../views/Skill/SkillDetails/SkillDetails.vue')
      },
      {
        path: '/SkillExercise',
        name: 'SkillExercise',
        component: () =>
          import('../views/Skill/SkillExercise/SkillExercise.vue')
      },
      {
        path: '/SkillExerciseTask',
        name: 'SkillExerciseTask',
        component: () =>
          import('../views/Skill/SkillExerciseTask/SkillExerciseTask.vue')
      },
      {
        path: '/DataShare',
        name: 'DataShare',
        component: () => import('../views/DataShare/DataShare.vue')
      },
      {
        path: '/UserCenter',
        name: 'UserCenter',
        component: () => import('../views/UserCenter/UserCenter.vue'),
        children: [
          {
            path: '/ExerciseBookList',
            name: 'ExerciseBookList',
            component: () =>
              import(
                '../views/UserCenter/ExerciseBookList/ExerciseBookList.vue'
              )
          },
          {
            path: '/DictionaryList',
            name: 'DictionaryList',
            component: () =>
              import('../views/UserCenter/DictionaryList/DictionaryList.vue')
          },
          {
            path: '/ExamMemoryList',
            name: 'ExamMemoryList',
            component: () =>
              import('../views/UserCenter/ExamMemoryList/ExamMemoryList.vue')
          },
          {
            path: '/CollectList',
            name: 'CollectList',
            component: () =>
              import('../views/UserCenter/CollectList/CollectList.vue')
          }
        ]
      },
      {
        path: '/UserCenterExerciseBookDetails',
        name: 'UserCenterExerciseBookDetails',
        component: () =>
          import(
            '../views/UserCenter/ExerciseBookDetails/ExerciseBookDetails.vue'
          )
      },
      {
        path: '/UserCenterExerciseBookDetailsTwo',
        name: 'UserCenterExerciseBookDetailsTwo',
        component: () =>
          import(
            '../views/UserCenter/ExerciseBookDetailsTwo/ExerciseBookDetailsTwo.vue'
          )
      },
      {
        path: '/Exercises',
        name: 'Exercises',
        component: () => import('../views/Exercises/Exercises.vue')
      },
      {
        path: '/ExercisesList',
        name: 'ExercisesList',
        component: () => import('../views/ExercisesList/ExercisesList.vue')
      },
      {
        path: '/Walkman',
        name: 'Walkman',
        component: () => import('../views/Walkman/Walkman.vue'),
        meta: { 
          requiresAuth: true 
        }
      },

      // 模考
      {
        path: '/exam/list',
        name: 'examList',
        component: () => import('../views/mockExam/list')
      },
      {
        path: '/exam/detail',
        name: 'examDetail',
        component: () => import('../views/mockExam/detail')
      },
      {
        path: '/exam/test',
        name: 'examTest',
        component: () => import('../views/mockExam/exam'),
        meta: {
          fullscreen: true
        }
      }
    ]
  },
  // {
  //   path: '/',
  //   name: 'Login',
  //   component: () => import('../views/LoginOld/Login.vue')
  // },
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login/login/index')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/login/register')
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import('../views/login/forget')
  },
  {
    path: '/bind',
    name: 'bindMobile',
    component: () => import('../views/login/bindMobile')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    var userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
    // 检查用户是否是 VIP
    if (!userInfo || userInfo.vip.code == 0) {
      // 如果不是 VIP，重定向到登录页面或者其他页面
      next({ path: '/vip' });

    } else {
      // 如果是 VIP，允许访问
      next();
    }
  } else {
    // 如果不需要vip权限，直接访问
    next();
  }
});

export default router
