/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios'
import QS from 'qs'
import store from '../store'
// import i18n from "@/i18n/i18n";
import { Message } from 'element-ui'
import md5 from 'js-md5'

const isIE = () => {
  if (!!window.ActiveXObject || 'ActiveXObject' in window) {
    return true
  } else {
    return false
  }
}

axios.defaults.baseURL = process.env.API_HTTP_ADDRESS

// 请求超时时间
axios.defaults.timeout = 60000
axios.defaults.withCredentials = true

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'
axios.defaults.headers.post['lang'] = localStorage.lang
axios.defaults.headers.timestamp = Math.round(Date.parse(new Date()) / 1000)
axios.defaults.headers.assceetoken = localStorage.getItem('token')
axios.defaults.headers.sign = md5(
  localStorage.getItem('token') +
    Date.parse(new Date()) +
    'base64:CtA0GeQuusVNLxJE+aB0eP2vvw5Rof0kG1VTBQM/GK0='
)
// timestamp
// 设置assceetoken在login/index.js login方法中
// axios.defaults.headers.assceetoken = sessionStorage.getItem('token');
axios.defaults.headers.lang = localStorage.lang
// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    config.headers.PageRouter = window.location.href
    const token = store.state.token
    token && (config.headers.Authorization = token)
    return config
  },
  (error) => {
    return Promise.error(error)
  }
)

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.code == 405) {
        Message.info('登录过期，请重新登录')
        // 清除token
        localStorage.removeItem('token')
        // store.commit('loginSuccess', null);
        // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
        setTimeout(() => {
          window.location.href = './'
        }, 1000)
      } else {
        return Promise.resolve(response)
      }
    } else {
      return Promise.reject(response)
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 405:
          this.$Message.info('登录过期，请重新登录')
          // 清除token
          localStorage.removeItem('token')
          setTimeout(() => {
            window.location.href = './'
          }, 1000)
          break
        default:
          alert(error.response.data.message)
      }
      return Promise.reject(error.response)
    }
  }
)
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: {
          ...params,
          ...(isIE ? { t: Date.now() } : {})
        }
      })
      .then((res) => {
        let codeValue = res.data.code
        switch (codeValue) {
          case 200:
            resolve(res.data)
            break
          case 301:
            window.location.href = res.url
            break
          default:
            Message.error(res.data.msg)
            resolve(res.data)
        }
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, QS.stringify(params))
      .then((res) => {
        let codeValue = res.data.code
        switch (codeValue) {
          case 200:
            resolve(res.data)
            break
          case 301:
            window.location.href = res.url
            break
          default:
            Message.success(res.data.msg)
            resolve(res.data)
        }
        if (res.data.indexOf('AccessControl denied') > 0) {
          window.location.href = '/#/Error302'
        }
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function getToken(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
/**
 * post方法，对应post请求--用于图片上传
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function postFormData(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, {
        headers: { 'Content-Type': 'multipart/form-data;charse=UTF-8' },
        xhrFields: { withCredentials: true }
      })
      .then((res) => {
        let codeValue = res.data.code
        switch (codeValue) {
          case 200:
            resolve(res.data)
            break
          default:
            Message.success(res.data.msg)
            resolve(res.data)
        }
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}
