import { apiLoginout, apiUserInfo } from '@/api/api.js'
import { apiGetHelper } from '@/api/userInfo'

export default {
  namespaced: true,
  state: {
    userInfo: {},
    helperInfo: {}
  },
  mutations: {
    UPDATE_USER_INFO(state, payload) {
      state.userInfo = {
        ...payload
      }
    },
    UPDATE_HELPER_INFO(state, payload) {
      state.helperInfo = {
        ...payload
      }
    }
  },
  actions: {
    GetUserInfo({ commit, dispatch }) {
      return apiUserInfo().then((res) => {
        commit('UPDATE_USER_INFO', res.data)
        sessionStorage.setItem('userInfo', JSON.stringify(res.data))

        dispatch('GetHelperInfo')
      })
    },
    GetHelperInfo({ commit }) {
      apiGetHelper({
        site_key: 'WEBSITE_CUSTOMER_QRCODE'
      }).then((res) => {
        commit('UPDATE_HELPER_INFO', res && res.data ? res.data[0] : {})
      })
    }
  }
}
