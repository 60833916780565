const calcStyle = (el, force) => {
  console.log(el.getBoundingClientRect().top)
  const top = el.getBoundingClientRect().top
  const maxHeight = window.innerHeight
  const zoom = Number(document.body.style.zoom) || 1
  const minHeight = maxHeight / zoom - top

  el.style.minHeight = `${minHeight}px`
  if (force) {
    el.style.height = `${minHeight}px`
  }
}

export default {
  bind: (el, { modifiers: { force } }) => {
    el.winResizeFunc = () => {
      calcStyle(el, force)
    }

    window.addEventListener('resize', el.winResizeFunc, false)
  },
  inserted: (el, { modifiers: { force } }) => {
    calcStyle(el, force)
  },
  componentUpdated: (el, { modifiers: { force } }) => {
    calcStyle(el, force)
  },
  unbind: (el) => {
    window.removeEventListener('resize', el.winResizeFunc, false)
  }
}
