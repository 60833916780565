import emitter from '@/utils/emitter'

function calcPosition(scrollTop) {
  const {
    0: { width, height, left, top }
  } = this.getClientRects()
  const winHeight = window.innerHeight
  const bottomEl = this.firstChild

  if (winHeight > height + top) {
    bottomEl.style.transition = 'none'
    bottomEl.style.position = 'relative'
    bottomEl.style.bottom = '0px'
  } else if (bottomEl.style.position !== 'fixed') {
    bottomEl.style.position = 'fixed'
    bottomEl.style.transition = 'none'
    bottomEl.style.maxWidth = `${width}px`
    bottomEl.style.width = '100%'
    bottomEl.style.bottom = `${-1 * height}px`
    setTimeout(() => {
      bottomEl.style.transition = 'bottom 0.3s ease-in-out'
      bottomEl.style.bottom = '0px'
    }, 100)
  }
}

export default {
  bind: (el) => {},
  inserted: (el) => {
    el.calcPosFunc = calcPosition.bind(el)
    emitter.on('SCROLL', el.calcPosFunc)
    emitter.on('RESIZE', el.calcPosFunc)

    const {
      0: { width, height }
    } = el.getClientRects()
    el.style.maxWidth = `${width}px`
    el.style.height = `${height}px`
  },
  update: (el) => {
    el.calcPosFunc(0)
  },
  unbind: (el) => {
    emitter.off('SCROLL', el.calcPosFunc)
    emitter.off('RESIZE', el.calcPosFunc)
  }
}
