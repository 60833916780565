import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n/i18n'
import '@/common/css/common.less'
import '@/assets/font/iconfont.css'
import '@/common/css/editor.scss'
import 'babel-polyfill'
import emitter from '@/utils/emitter'
import { throttle } from 'lodash'

import GSignInButton from 'vue-google-signin-button'
Vue.use(GSignInButton)

import { StripePlugin } from '@vue-stripe/vue-stripe'
// 测试环境： 'pk_test_51J78WRJ8fz9LEfCjEUyw8b1CJlIeazPgdgTV9xwMNly3DdpZgomJS2KaBMZKq8tzcDZnKPaRv2xXvQMcf9kRM3CA00aOzgzDmW'
// 正式环境： 'pk_live_51J78WRJ8fz9LEfCjlU5SAtJMR67wlDfUKSu2XzJXmvOudy5uGDc9ZkW3VxJZ7txQRp9COdA3VkWgEN1NcCLHmomA00jOFc0VNd'
Vue.use(StripePlugin, {
  pk:
    'pk_live_51J78WRJ8fz9LEfCjlU5SAtJMR67wlDfUKSu2XzJXmvOudy5uGDc9ZkW3VxJZ7txQRp9COdA3VkWgEN1NcCLHmomA00jOFc0VNd'
})

import '@/utils/adapter'

import Finally from 'promise.prototype.finally'
Finally.shim()

import promise from 'es6-promise'
promise.polyfill()

// layer引入
import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css'

Vue.prototype.$layer = layer(Vue)

// ele框架引入
import ElementUI, { Message } from 'element-ui'
import enLocale from 'element-ui/lib/locale/lang/en'
import chLocale from 'element-ui/lib/locale/lang/zh-CN'

// import 'element-ui/lib/theme-chalk/index.css';
import '@/theme/index.css'
//vueSwiper引入
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueCoreVideoPlayer from 'vue-core-video-player'

import Directive from '@/directive'
import Filters from '@/filter'

// require styles
import 'swiper/dist/css/swiper.css'
Vue.config.productionTip = false

// 为了实现Class的私有属性
const showMessage = Symbol('showMessage')

/**
 *  重写ElementUI的Message
 *  single默认值true，因为项目需求，默认只弹出一个，可以根据实际需要设置
 */
class DonMessage {
  success(options, single = true) {
    this[showMessage]('success', options, single)
  }
  warning(options, single = true) {
    this[showMessage]('warning', options, single)
  }
  info(options, single = true) {
    this[showMessage]('info', options, single)
  }
  error(options, single = true) {
    this[showMessage]('error', options, single)
  }

  [showMessage](type, options, single) {
    if (single) {
      // 判断是否已存在Message
      if (document.getElementsByClassName('el-message').length === 0) {
        Message[type](options)
      }
    } else {
      Message[type](options)
    }
  }
}

Vue.use(Directive)
Vue.use(Filters)
Vue.use(ElementUI, {
  locale: i18n.locale === 'ch' ? chLocale : enLocale
})
Vue.prototype.$message = new DonMessage()
Vue.use(VueAwesomeSwiper)
Vue.use(VueCoreVideoPlayer, {
  lang: i18n.locale === 'ch' ? i18n.messages.ch : i18n.messages.en
})

window.addEventListener(
  'resize',
  throttle(() => {
    emitter.emit('RESIZE')
  }, 50)
)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
