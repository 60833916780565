const TYPES = {
  START: {
    time: 2 * 60,
    onEnd: 'next',
    warningTime: 0
  },
  SPEAK: {
    time: 35 * 60,
    onEnd: 'nextPart',
    warningTime: 5 * 60
  },
  WRITE: {
    time: 60 * 60,
    onEnd: 'nextPart',
    warningTime: 5 * 60
  },
  READ: {
    time: 41 * 60,
    onEnd: 'nextPart',
    warningTime: 5 * 60
  },
  OPTIONALBREAK: {
    time: 10 * 60,
    onEnd: 'next',
    warningTime: 0
  },
  LISTEN: {
    time: 58 * 60,
    onEnd: 'nextPart',
    warningTime: 5 * 60
  },
  NONE: {
    hide: true,
    time: 0,
    onEnd: '',
    warningTime: 0
  }
}

let timeId = 0
let loadingTimeId = 0

export default {
  namespaced: true,
  state: {
    info: {},
    current: {
      type: 'NONE',
      config: TYPES.NONE
    },
    time: {
      show: false,
      current: 0,
      warning: false,
      onEnd: ''
    },
    loading: false,
    event: {}
  },
  mutations: {
    UPDATE_INFO(state, payload) {
      state.info = {
        ...payload
      }
    },
    UPDATE_CURRENT(state, payload) {
      state.current = {
        ...payload
      }
    },
    UPDATE_TIME(state, payload) {
      state.time = {
        ...state.time,
        ...payload
      }
    },
    UPDATE_EVENT(state, payload) {
      state.event = {
        ...state.event,
        ...payload
      }
    },
    UPDATE_LOADING(state, payload) {
      state.loading = !!payload
    }
  },
  actions: {
    SetInfo({ commit }, info) {
      commit('UPDATE_INFO', info)
    },
    SetEvent({ commit }, { type, func }) {
      commit('UPDATE_EVENT', {
        [type]: func
      })
    },
    SetLoading({ commit }, loading) {
      commit('UPDATE_LOADING', loading)

      if (loading) {
        clearTimeout(loadingTimeId)
        // 10秒超时
        loadingTimeId = setTimeout(() => {
          commit('UPDATE_LOADING', false)
        }, 10000)
      }
    },
    SetTimeType({ commit, dispatch, state }, { type, force = false }) {
      if (state.info.type !== 1) {
        return false
      }

      const config = type ? TYPES[type] : null
      if (config && (state.current.type !== type || !!force)) {
        commit('UPDATE_CURRENT', {
          type,
          config,
          force
        })

        dispatch('RunTime')
      }
    },
    RunTime({ commit, state }) {
      const config = state.current.config
      if (!config) {
        return false
      }

      clearInterval(timeId)

      // 初始化时间
      commit('UPDATE_TIME', {
        show: !config.hide,
        current: config.time,
        warning: config.time <= config.warningTime,
        onEnd: config.onEnd
      })

      // 执行时间倒计时
      timeId = setInterval(() => {
        // 加载数据时,时间暂停
        if (state.loading) {
          return false
        }

        const currentTime = state.time.current - 1
        commit('UPDATE_TIME', {
          show: !state.current.config.hide,
          current: currentTime,
          warning: currentTime <= state.current.config.warningTime,
          onEnd: state.current.config.onEnd
        })

        // 为0时，结束倒计时，并执行函数
        if (currentTime <= 0) {
          clearInterval(timeId)
          if (state.event[state.time.onEnd]) {
            state.event[state.time.onEnd]()
          }
        }
      }, 1000)
    }
  }
}
