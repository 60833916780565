/**
 * api接口统一管理
 */
import { post, get, getToken, postFormData } from './request'

// 获取token
export const apiToken = (p) => getToken('api/login', p)

// 苹果授权登录
export const apiAppleLogin = (p) => get('/api/login/by-apple', p)

//通过code获取微信授权用户信息
export const apiWloginCallBack = (p) => get('api/wlogin_callBack', p)

// 苹果授权登录
export const apiAppleWebLogin = (p) => post('/api-web/login/by-apple-web', p)

// oss
export const apiSignUrl = (p) => post('api/oss/sign-url', p)

// -------------
// 登录
// -------------
// 获取区号
//export const apiCheckAreacode = p => get('api/check_areacode', p);
export const apiCheckAreacode = (p) => get('api-web/login/check-area-code', p)

// 发送短信接口
//export const apiSendMssage = p => post('api/SendMssage', p);
export const apiSendMssage = (p) => post('api-web/login/send-message', p)

// 发送注册短信接口
export const apiSendRegisterCode = (p) => post('api-web/login/user-reg-sms', p)

// 新用户注册
export const apiSaveRegister = (p) => post('api-web/login/user-reg', p)

// 找回密码发送短信
export const apiForgetPwdSendCode = (p) =>
  post('/api-web/login/find-pwd-sms', p)

// 找回密码
export const apiSaveForgetPwd = (p) => post('/api-web/login/find-pwd', p)

// 账号密码登录
export const apiPwdLogin = (p) => post('/api-web/login/by-pwd', p)

// 微信callback登录
export const apiWechatCbLogin = (p) => get('/api-web/login/by-wechat', p)

// 绑定手机发送验证码
export const apiBindMObileSendCode = (p) =>
  post('/api-web/login/bind-phone-sms', p)

// 微信登录绑定手机
export const apiWechatLoginBindMobile = (p) =>
  post('/api-web/login/wx-bind-phone', p)

// 手机号码登录
//export const apiPclogin = p => post('api/pclogin', p);
export const apiPclogin = (p) => post('api-web/login/pc-login', p)

// 绑定手机号
//export const apiPcbindUser = p => post('api/PcbindUser', p);
export const apiPcbindUser = (p) => post('api-web/login/pc-bind-user', p)

// 【退出登录】
//export const apiLoginout = p => get('api/loginout', p);
export const apiLoginout = (p) => get('api-web/login/pc-logout', p)

//修改密码
export const resetPwd = (p) => post('/api-web/user/reset-pwd', p)

// 【个人中心】
//获取用户信息
//export const apiUserInfo = p => get('api/user_info', p);
export const apiUserInfo = (p) => get('api-web/user/user-info', p)

//获取我的生词本列表
//export const apiMyRecordword = p => get('api/my_recordword', p);
export const apiMyRecordword = (p) => get('api-web/user/my-new-word', p)
//获取我的考试回忆
//export const apiMyrecall = p => get('api/myrecall', p);
export const apiMyrecall = (p) => get('api-web/user/my-recall', p)
//获取我的收藏
//export const apiMyCollect = p => get('api/my_collect', p);
export const apiMyCollect = (p) => get('api-web/user/my-collect', p)

// 收藏的分类
export const apiMyCollectCategory = (p) => get('api-web/collect/cate-list', p)

// 添加收藏分类
export const apiAddCollect = (p) => post('api-web/collect/cate-add', p)

// 删除收藏分类
export const apiDelMyCollect = (p) => post('api-web/collect/cate-del', p)

// 重命名收藏分类
export const apiRenameMyCollect = (p) => post('api-web/collect/cate-edit', p)

// 激活-vip
//export const apiVipactivate = p => post('api/vipactivate', p);
export const apiVipactivate = (p) => post('api-web/user/vip-active', p)
// 激活-credit
//export const apiRechargeCredit = p => post('api/rechargeCredit', p);
export const apiRechargeCredit = (p) => post('api-web/user/credit-recharge', p)

//获取Vip充值记录
//export const apiBuyVip = p => get('api/buyVip', p);
export const apiBuyVip = (p) => get('api-web/user/buy-vip', p)
//获取credit消耗记录
//export const apiUserUseCredit = p => get('api/user_use_credit', p);
export const apiUserUseCredit = (p) => get('api-web/user/credit-use', p)

// -------------
// 机经
// -------------
// 获取机经列表
export const apiGrelist = (p) => get('api/Grelist', p)
export const apiWebGrelist = (p) => get('api-web/gre/list', p)
// 获取机经详情
export const apiGreDetail = (p) => get('api/GreDetail', p)
export const apiWebGreDetail = (p) => get('api-web/gre/detail', p)
// 确认考过
export const apiCheckOk = (p) => get('api/check_ok', p)
export const apiWebCheckOk = (p) => get('api-web/gre/check-ok', p)
//翻译
export const apiWebTranslate = (p) => get('api-web/gre/translate', p)
//加入生词本
export const apiWebRecordWord = (p) => post('api-web/user/my-new-word-add', p)

//错误上报
export const apiErrorReport = (p) => post('api-web/gre/error-report', p)
//机经列表
export const apiGreList = (p) => get('api-web/gre/list-item', p)
//机经--讨论
export const apiGreCommentList = (p) => get('api-web/gre/comment-list', p)
//练习--讨论
export const apiQuestionCommentList = (p) =>
  get('api-web/question/comment-list', p)
//练习删除评论
export const apiDelQuestionComment = (p) =>
  post('api-web/question/comment-del', p)
//机经删除评论
export const apiDelGreComment = (p) => post('api-web/gre/comment-del', p)
//练习删除回复评论
export const apiDelQuestionReplyComment = (p) =>
  post('api-web/question/comment-reply-del', p)
//机经删除回复评论
export const apiDelGreReplyComment = (p) =>
  post('api-web/gre/comment-reply-del', p)

//练习--大家--大家(最新、热门、我的练习)
export const apiQuestionTryList = (p) => get('api-web/question/try-list', p)
// 作业列表
export const apiQuestionWorkList = (p) =>
  get('api-web/question/my-job-try-list', p)
//最近联系
export const apiQuestionRecordList = (p) =>
  get('api-web/question/my-record-list', p)
//删除我的练习
export const apiDelMyTry = (p) => post('api-web/question/my-try-del', p)
//回复讨论
export const apiReplayAdd = (p) => post('api-web/comm/replay-add', p)
//发表练习讨论
export const apiCommentAdd = (p) => post('api-web/question/comment-add', p)
//点赞
export const apiCommentLike = (p) => post('api-web/comm/like-add', p)
//提问老师接口
export const apiUserAskList = (p) => get('api-web/question/user-ask-list', p)
//查看提问老师
export const apiUserAskView = (p) =>
  get('api-web/question/user-ask-view-pay', p)
//题型列表
export const apiGreType = (p) => get('api-web/gre/cate-stat', p)
//ai识别语音
export const apiAiText = (p) => get('api-web/comm/voice-to-text', p)
//ai打分
export const apiAiCore = (p) => post('api-web/sing-sound/mark', p)
//发表机经讨论
export const apiGreCommentAdd = (p) => post('api-web/gre/comment-add', p)
//上一次考过
export const apiExamLastPoint = (p) => get('api-web/gre/exam-last-point', p)
//考过记录
export const apiExamAdd = (p) => post('/api-web/gre/exam-add', p)

// -------------
// 机经练习
// -------------
//根据条件获取题目
export const apiGetQuestionList = (p) => get('api/getQuestionList', p)
export const apiGetWebQuestionList = (p) => get('api-web/question/list', p)
// 获取题目详情
export const apiGetQuestion = (p) => get('api/getQuestion', p)
export const apiGetWebQuestion = (p) => get('api-web/question/detail', p)
// 获取老师详解
export const apiGetTeacherDetail = (p) => get('api/getTeacherDetail', p)
export const apiGetWebTeacherDetail = (p) =>
  get('api-web/question/teacher-detail', p)
// 向老师提问
export const apiAskQuestions = (p) => post('api/AskQuestions', p)
export const apiWebAskQuestions = (p) => post('api-web/question/ask', p)

// 提交问题
export const apiQuestionSave = (p) => postFormData('api/QuestionSave', p)
export const apiWebQuestionSave = (p) =>
  postFormData('api-web/question/answer', p)

//获取题目数量
export const apiWebListStat = (p) => get('api-web/gre/list-stat', p)

// ----------
// 字幕答案，答题记录
// ----------
// 获取老师示范
export const apiGetDemonstration = (p) => get('api/getDemonstration', p)
export const apiWebGetDemonstration = (p) =>
  get('api-web/question/teacher-demo', p)
// 我的答题记录
export const apiGetMyRecord = (p) => get('api/MyRecord', p)
export const apiWebGetMyRecord = (p) => get('api-web/question/my-record', p)

// 练习题分类数量
export const apiQuestionCateCount = (p) =>
  get('api/v1/practice/questionCateCount', p)
export const apiWebQuestionCateCount = (p) =>
  get('api-web/practice/cate-count', p)

// 添加收藏
export const apiCollect = (p) => post('api/topic_collect', p)
export const apiWebCollect = (p) => post('api-web/topic/collect-add', p)

// 删除收藏
export const apiDelCollect = (p) => post('api/del_collect', p)
export const apiWebDelCollect = (p) => post('api-web/topic/collect-del', p)

// 考场回忆
//考场回忆
//export const apiGecall = p => get('api/recall', p);
export const apiGecall = (p) => get('api-web/gre/recall', p)
//考试回忆某一天全部数据
//export const apiDatePoint = p => get('api/date_point', p);
export const apiDatePoint = (p) => get('api-web/gre/point-date', p)
//新增机经获取考点信息
//export const apiGetpoint = p => get('api/getpoint', p);
export const apiGetpoint = (p) => get('api-web/gre/point-list', p)
// 搜索类似机经
//export const apiSearchGre = p => post('api/search_gre', p);
export const apiSearchGre = (p) => post('api-web/gre/search', p)
// 新增机经
//export const apiGreStore = p => post('api/Gre_store', p);
export const apiGreStore = (p) => post('api-web/gre/add', p)
//获取分类接口
//export const apiGetclassify = p => get('api/getclassify', p);
export const apiGetclassify = (p) => get('api-web/gre/cate-list', p)

// 【VIP】
//获取时长说明
//export const apiGetexplain = p => get('api/getexplain', p);
export const apiGetexplain = (p) => get('api-web/gre/info-time', p)
//获取CREDIT使用说明
//export const apiClassfyUseup = p => get('api/classfy_useup', p);
export const apiClassfyUseup = (p) => get('api-web/gre/cate-use', p)

// 【消息】
//获取消息列表
//export const apiPcNotifyList = p => get('api/PcNotifyList', p);
export const apiPcNotifyList = (p) => get('api-web/msg/list', p)
// 全部已读
export const apiPCNotifyAllRead = (p) => get('api-web/msg/msgReadAll', p)
// 删除消息
export const apiPcNotifyDelete = (p) => post('api-web/msg/del', p)
//获取消息详情
//export const apiNotifyDeail = p => get('api/notify_deail', p);
export const apiNotifyDeail = (p) => get('api-web/msg/detail', p)
//获取老师回复详情
//export const apiTeacherReplay = p => get('api/teacher_replay', p);
export const apiTeacherReplay = (p) => get('api-web/msg/teacher-reply', p)

// 【资料共享】
//获取文件共享列表
//export const apiShareFile = p => get('api/share_file', p);
export const apiShareFile = (p) => get('api-web/share/file-list', p)

// 【搜索】
//获取搜索分类
//export const apiGetSearchCate = p => get('api/getSearchCate', p);
export const apiGetSearchCate = (p) => get('api-web/search/search-cate', p)

//搜索
//export const apiSearchPc = p => get('api/SearchPc', p);
export const apiSearchPc = (p) => get('api-web/search/result', p)

// 【技巧增强】
//获取用户信息
//export const apiCourserPc = p => get('api/courserPc', p);
export const apiCourserPc = (p) => get('api-web/course/list', p)
//技巧增强详情
//export const apiPccourseDetail = p => get('api/Pccourse_detail', p);
export const apiPccourseDetail = (p) => get('api-web/course/detail', p)
//获取技巧增强小节详情
//export const apiUcld = p => get('api/ucld', p);
export const apiUcld = (p) => get('api-web/course/summary', p)
//保存用户小节答题
//export const apiUserAddPrac = p => post('api/user_add_prac', p);
export const apiUserAddPrac = (p) => post('api-web/course/practice-add', p)
//删除我的练习录音
//export const apiUserAiduoDel = p => post('api/user_aiduo_del', p);
export const apiUserAiduoDel = (p) => post('api-web/course/audio-del', p)
// 【其他作业本】
//export const apiPracticeUnit = p => get('api/getPracticeUnit', p);
export const apiPracticeUnit = (p) => get('api-web/course/practice-unit', p)

// 【我的作业】
//export const apiPracticeJob = p => get('api/getPracticeJob', p);
export const apiPracticeJob = (p) => get('api-web/course/practice-job', p)

// 作业上一题，下一题
export const apiPracticeJobNext = (p) => get('/api-web/user/myjob-prev-next', p)

// 【我的练习】
//export const apiPracticeRecord = p => get('api/getPracticeRecord', p);
export const apiPracticeRecord = (p) => get('api-web/course/practice-record', p)

// 【完成作业】
//export const apiAddexericise = p => post('api/addexericise', p);
export const apiAddexericise = (p) => post('api-web/course/exercise-add', p)

//获取作业详情题目列表
//export const apiJobDetail = p => get('api/job_detail', p);
export const apiJobDetail = (p) => get('api-web/class/job-detail', p)
//获取个人中心作业列表
//export const apiMyJobList = p => get('api/myJobList', p);
export const apiMyJobList = (p) => get('api-web/class/my-job-list', p)

// 【单元题目列表】
//export const apiexericiseList = p => get('api/exericiseList', p);
export const apiexericiseList = (p) => get('api-web/class/exercise-list', p)
//获取用户所在班级的所有老师
//export const apiGetClassTeacher = p => get('api/getClassTeacher', p);
export const apiGetClassTeacher = (p) => get('api-web/class/teacher-list', p)

//首页

//首页我的个人使用情况的统计
export const apiUserHomeStat = (p) => get('/api-web/user/home-stat', p)
// 首页我的个人详细使用清空统计
export const apiUserHomeAllStat = (p) => get('/api-web/user/home-stat', p)
//首页的机经更新的数据统计
export const apiGreHomeStat = (p) => get('/api-web/gre/home-stat', p)
//设置我的个人目标和考试日期
export const apiSetMyGoal = (p) => post('/api-web/user/set-my-goal', p)
//我的作业本--列表
export const apiUserMyJobList = (p) => get('/api-web/user/myjob-list', p)
// 我的班级列表
export const apiUserClassList = (p) => get('/api-web/class_index', p)
//共享资料下载的列表
export const apiShareFileList = (p) => get('/api-web/share/file-list', p)
//生词本列表
export const apiMyNewWordList = (p) => get('/api-web/user/my-new-word', p)
//生词本删除
export const apiMyNewWordDel = (p) => post('/api-web/user/my-new-word-del', p)
//生词本新增
export const apiMyNewWordAdd = (p) => post('/api-web/user/my-new-word-add', p)
//班级列表
export const apiMyClassList = (p) => get('/api-web/user/my-class-list', p)
//个人使用情况的统计
export const apiMyGoal = (p) => get('/api-web/user/get-my-goal', p)

// 学习计划
export const apiPlanInfo = (p) => get('/api-web/study-plan/plan-stat', p)
export const apiPlanDayStat = (p) => get('/api-web/study-plan/day-info', p)

//会员
//会员套餐的列表---按月购买的
export const apiVipMonthList = (p) =>
  get('/api-web/user/vip-pack-month-list', p)
//会员套餐的列表---单独购买点券
export const apiVipCreditList = (p) =>
  get('/api-web/user/vip-pack-credit-list', p)
//会员套餐--购买记录
export const apiVipBuyList = (p) => get('/api-web/user/vip-pack-buy-list', p)
//会员套餐--使用记录
export const apiVipUseList = (p) => get('/api-web/user/vip-pack-use-list', p)
//点击选择购买时候的下单
export const apiVipPayAdd = (p) => get('/api/pay/vip-h5-pay-add', p)
//去获取支付需要的微信返回的二维码信息
export const apiVipWxUnify = (p) => get('/api/pay/wx-h5-unify', p)
//去获取支付需要的支付宝返回的二维码信息
export const apiVipAliUnify = (p) => get('/api/pay/ali-h5-unify', p)
//定时请求一下是否支付成功的接口
export const apiVipPayResult = (p) => get('/api/pay/pay-h5-result', p)
//免费领取
export const apiVipFirstGet = (p) => get('/api-web/user/vip-first-get', p)
//免费领取关闭
export const apiVipFirstClose = (p) => get('/api-web/user/vip-first-close', p)
//激活会员的VIP或者credit的激活码
export const apiVipCreditActive = (p) =>
  post('/api-web/user/vip-credit-active', p)

//WFD正确率统计
export const apiWFDRate = (p) => get('/api-web/question/score-rate-stat', p)

//单词库--分类
export const apiBaseWordType = (p) => get('/api-web/word/base-cate', p)
//单词库--列表
export const apiBaseWordList = (p) => get('/api-web/word/base-list', p)
//单词库--随机列表
export const apiBaseWordRandList = (p) => get('/api-web/word/base-list/rand', p)
//我的单词表--分类列表
export const apiWordTypeList = (p) => get('/api-web/word/user-cate-list', p)
//我的单词表--分类列表--添加
export const apiWordTypeAdd = (p) => get('/api-web/word/user-cate-add', p)
//我的单词表--分类列表--编辑重命名
export const apiWordTypeEdit = (p) => get('/api-web/word/user-cate-edit', p)
//我的单词表--分类列表--删除该分组和下面的单词
export const apiWordTypeDel = (p) => get('/api-web/word/user-cate-del', p)
//我的单词表--单词列表
export const apiWordList = (p) => get('/api-web/word/user-word-list', p)
//我的单词表--单词--添加
export const apiWordAdd = (p) => get('/api-web/word/user-word-add', p)
//我的单词表--单词--删除
export const apiWordDel = (p) => get('/api-web/word/user-word-del', p)
// 我的单词表 清空某一个词库的学习数据
export const apiBaseWordClear = (p) => get('/api-web/word/base-learn-clean', p)
export const apiUserWordClear = (p) => get('/api-web/word/user-learn-clean', p)
//我的单词表--学习状态标识
export const apiWordUserMark = (p) => get('/api-web/word/user-learn-mark', p)
//基础单词库--学习状态标识
export const apiWordBaseMark = (p) => get('/api-web/word/base-learn-mark', p)
export const apiBaseWordListOne = (p) => get('/api-web/word/base-list-one', p)
export const apiWordListOne = (p) => get('/api-web/word/user-word-list-one', p)
