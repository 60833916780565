import * as time from './time'

const filterMap = {
  ...time
}

export default {
  install: (Vue) => {
    Object.keys(filterMap).forEach((key) => {
      Vue.filter(key, filterMap[key])
    })
  }
}
