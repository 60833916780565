const en = {
  Home: {
    title: 'A new day, continue to work towards your goal!',
    manText: 'Courses and classes',
    userStat: 'Target score',
    distance: 'Distance test',
    day: 'day',
    practice: 'Total practice',
    today: 'Today have to practice',
    topic: 'topic',
    topicLibrary: 'The question bank',
    navStudyData: 'Viewing learning data',
    totalText: 'The cumulative learning',
    study: 'Continuous learning',
    highest: 'Highest record',
    tested: 'Recently, it has been tested',
    branch: 'Branch',
    process: 'In the learning process',
    individual: 'individual',
    member: 'member',
    openClass: 'Open class',
    ClassClass: 'Class curriculum',
    Favorites: 'Favorites',
    whole: 'All',
    DataDownload: 'Data download',
    MachineUpdate: 'Machine update',
    updated: 'Updated',
    Totalbanks: 'Total number of question banks',
    TotalFrequency: 'Total high frequency',
    Updated: 'Updated this week',
    SuccessRate: 'Success rate',
    exerciseBook: 'Exercise book',
    teacher: 'Teacher',
    already: 'Already existing',
    completion: 'Human completion',
    Release: 'Release time',
    deadline: 'Deadline',
    NoRecord: 'No record',
    ToCompleted: 'To be completed',
    Home: 'Return to home page',
    class: 'Screening class',
    see: 'See',
    finish: 'To finish',
    JobList: 'Job list',
    JobEvaluation: 'Job evaluation',
    Overall: 'Overall job evaluation',
    Recent: 'Recent high frequency prediction',
    Download: 'Download of reference materials',
    word: 'Word',
    Phonetic: 'Phonetic symbols',
    interpretation: 'Interpretation',
    objectives: 'Exam Goal',
    zha: 'fried',
    divide: 'Equally divided',
    speak: 'spoken language',
    write: 'writing',
    read: 'reading',
    listen: 'hear',
    addScore: 'Add small score',
    examDate: 'Exam date',
    choiceDate: 'Choose date',
    oldScore: 'historical score',
    sure: 'Sure',
    settingSuccess: 'Set successfully',
    scoreRange: 'Range 10-90',
    noDigit: 'Decimals are not supported',
    setting: 'Setting'
  },
  Pra: {
    title: 'Selected target points must practice question type',
    below: '35 Score and below',
    points: ' Score',
    topPoints: '79 Score and above',
    MustPractice: 'Must practice',
    Success: 'Success rate',
    Return: 'Return to job list',
    Return2: 'Return to course',
    current: 'Current',
    topic: 'Topic',
    common: 'Common',
    Check: 'Check the original question',
    Return3: 'Returns a list of search results',
    ReturnMessage: 'Return notification message',
    Return4: 'Return to Favorites',
    title2:
      'Explanation of official question types and explanation of question answering skills',
    Selected: 'Check the list',
    Practice: 'Practice progress',
    More: 'More filter criteria',
    Tips: 'Tips',
    filter: 'Filtered by current criteria',
    Submit: 'Submit',
    shiFan: 'Teacher demonstration',
    subtitle: 'Subtitle',
    yes: 'Yes',
    Collection: 'Favorite',
    error: 'Report',
    share: 'Share',
    tiWen: 'Put questions to',
    vido: 'Video Explanation',
    redo: 'Try again',
    Last: 'Previous',
    Next: 'Next',
    add: 'add to favorites',
    text: 'Up to 6 words',
    text2: 'Add group',
    confirm: 'Determine',
    tips: 'Collection saved successfully',
    AI: 'AI score remaining today',
    second: 'Second',
    Fill: 'Fill in the test information',
    text3:
      'Your test information and memories will be sorted and collected, and help you get more accurate high-frequency ranking and prediction.',
    test4: 'Test date',
    Required: 'Required',
    Please: 'Please select a year',
    text5: 'Select year (within the last two years only)',
    text6: 'Please select a month',
    text7: 'Examination room',
    text8: 'Last selected:',
    text9: 'Select country',
    text10: 'Select city',
    text11: 'Select examination room',
    memory: 'Memory',
    determine: 'Determine',
    text12: 'Turn on microphone permissions',
    text13:
      'Click "always allow XX to use your microphone" as shown in the figure below.',
    text14:
      'For the best experience, we recommend that you use the latest version of Google browser.',
    text15:
      'Your microphone permission is not turned on. You can record normally only after it is turned on.',
    text16: 'How do I turn it on?',
    text17: 'Open member',
    text18:
      'You have reached the maximum number of AI scores today. Open PTEGO members and enjoy the privilege of unlimited use of AI scoring function.',
    text19: 'To open',
    text20: 'AI score',
    text21: 'AI scoring takes about 20-40 seconds',
    text22: 'You can close this page and check it later',
    text23: 'Scoring item',
    text24: 'Score (1)',
    text25: 'Score comments',
    text26: 'Official standard',
    text27: 'Content points',
    text28: 'Correct word',
    text29: 'Incorrect words, poor pronunciation, unrecognized pronunciation',
    text30: 'Total score',
    text31: 'My exercise:',
    text32: 'Scoring item',
    text33: 'Score (90)',
    text34: 'Score comments',
    fluency: 'Fluency',
    text35: 'Pronunciation score',
    text36: 'Content points',
    text37: 'See the intelligent recognition results below for details.',
    text38: 'Total score',
    text39: 'My exercise:',
    text40: 'Intelligent recognition results',
    text41: 'Excellent',
    good: 'Good',
    difference: 'Difference',
    text42: '(missed reading)',
    text43: 'Read more',
    text44: 'Record it again',
    text45: 'View AI score',
    text46: 'Trying to score. Please check later',
    text47: 'In scoring',
    text48: 'Start AI scoring',
    text49: 'Please submit the exercise recording first',
    text50: 'View intelligent identification',
    text51: 'Identifying',
    answer: 'Answer',
    score: 'Score',
    text52: 'Correct rate',
    analysis: 'Analysis',
    text53: 'Meaning Group',
    text54: 'Tips for meaning group segmentation',
    text55: 'View the Yiqun User Guide',
    stress: 'Stress',
    text56: 'For accent location tips',
    text57: 'View the stress Guide',
    text58: 'Is the division position of the meaning group',
    text59: 'Ventilation position for sentence break',
    text60: 'View the guide to using punctuation',
    pause: 'Division',
    text61: 'A purpose',
    translate: 'Translate',
    text62: 'Use of punctuation',
    text63: 'Video Explanation',
    text64: 'Text explanation',
    text65: 'What is sentence breaking function?',
    text66:
      'When the "sentence breaking" function in the lower right corner of the practice interface is turned on, students will see two symbols in the text:',
    text67:
      'The first one is the small arrow pointing down to the green, representing the location of the group.',
    text68:
      'The second is the red double slash, which represents the pause and breathing position of the sentence.',
    Ventilate: 'Ventilate',
    text69:
      'The correct expression of meaning group and ventilation position is related to the score of fluidity in PTE oral English.',
    text70: 'How to practice breaking sentences?',
    text71: 'Combine 2-3 words into phrases and practice continuous reading.',
    text72:
      'Find the position of meaning group segmentation and practice meaning group connection.',
    text73:
      'Confirm the ventilation position in the text and read aloud in combination with the meaning group.',
    text74:
      'Note: only pause at "/ /" and breathe“ ↘” There is no need to pause, and attention should be paid to the processing of stress, weak reading and continuous reading in each meaning group.',
    text75:
      'For more specific examples, please step by step to watch the demonstration in the explanation video of sentence breaking function.',
    text76: 'Use of stress',
    text77: 'What is stress function?',
    text78:
      'When the "stress" function in the lower right corner of the practice interface is turned on, students will see some red origin at the top of the text. The position of the origin indicates the position of the stress.',
    example: 'Example',
    text79:
      'The correct expression of word stress and sentence stress is the key point of PTE oral English.',
    text80:
      'How to reflect word stress in practice? Do you read hard on stressed syllables?',
    text81:
      'Word stress is not obtained by hard reading on stressed syllables, but the normality of stressed syllables and weak reading of other syllables. Through this contrast, accented syllables sound heavier than other syllables.',
    text82: 'For example',
    text83:
      'For example, if the stress of this word is in the middle, first find a mu with normal volume, and then weakly read other syllables, you can get a community pronunciation with correct stress.',
    text84: 'What is sentence stress? How to achieve sentence stress?',
    text85:
      "When reading a sentence, we don't emphasize every word, but those words with practical meaning (content words). For words with no practical meaning (functional words), we need to read them weakly.",
    text86: 'Do the weak reading and continuous reading of functional words',
    text87:
      'The key to having sentence stress is the weak pronunciation of functional words (vowel weakening vowel)Like word stress, we should avoid the pronunciation habit of "hard work" in sentence stress. When reading content words, you can use the normal voice to make the content words sound stressed through the weak reading and continuous reading of functional words.',
    text89: 'Do meaning group continuous reading',
    text90:
      'The sentence is divided into several meaning groups. The so-called meaning group refers to each component divided according to meaning and structure in the sentence. Each component is called a meaning group. Words in the same meaning group are closely related to words and cannot be separated at will, otherwise it will cause misunderstanding. The words at the end of the meaning group should have the final sound landing, bring out the sentence structure and highlight the sentence stress through the continuous reading of the meaning group.',
    text91:
      'For more specific examples, please watch the demonstration in the stress function explanation video step by step.',
    text92: 'AI intelligent recognition',
    text93: 'Original title',
    text94: 'My exercise:',
    text95: 'Intelligent recognition results',
    text96: 'Correct',
    Misreading: 'Misreading',
    text97: 'No voice detected',
    text98: 'Please select the question type',
    text99: 'Please describe the problem in detail',
    text100: 'Ask the teacher questions',
    text101: 'Select the audio you want to submit',
    lately: 'lately',
    PracticeAudio: 'Practice audio',
    NoAudio: 'No audio recording',
    text102: 'Please submit your exercise and ask questions first.',
    text103: 'Your problem description',
    text104: 'This question will consume',
    text105: 'Counting coupons',
    text106:
      'Please describe the problem here; Read the history before asking questions to avoid repetition ~',
    text107: 'Trying to score. Please check later',
    Folded: 'Folded',
    Records: 'Records',
    text108: "Let's discuss it with you",
    publish: 'Publish',
    discuss: 'Discuss',
    text109: 'The discussion has been deleted',
    reply: 'Reply',
    text110: 'This exercise will be submitted to the teacher for correction',
    Yours: 'Yours',
    text111: 'Record of the latest exercise',
    text112:
      'It will be submitted to the teacher for correction. It is recommended to practice repeatedly to the best state.',
    bx: 'Practice performance',
    Average: 'Average accuracy',
    everybody: 'Everybody',
    my: 'My',
    text113: 'Average correct rate of this question',
    text114: 'Recent accuracy',
    text115: 'Target accuracy',
    text116: 'Not data',
    text117: 'Used by students',
    text118: 'put questions to',
    text119:
      '"After the function, the teacher will reply within 24 hours, and the reply content will be displayed here. The questioning function needs to be consumed“',
    text120: 'Coupons can be purchased at the member center',
    text121: 'Explanation of question type',
    text122: 'Selected topic',
    text123: 'Question bank total',
    text124: 'Questions, filtered out',
    text125: ', the filter conditions are:',
    text126: 'Explain in detail'
  },
  Mock: {
    title: 'My record',
    paper: 'Model test paper',
    name: 'Model test name',
    progress: 'Speed of progress',
    Creation: 'Creation time',
    operation: 'Operation',
    Completed: 'Completed',
    score: 'Has score',
    see: 'See',
    continue: 'Continue',
    delete: 'Delete',
    There: 'There is no historical model test record',
    vipTips:
      'Ptego not only has single question practice model test and mini model test, but also has a full simulation timing model test continuously updated according to high-frequency updates every month. Open members can use the above model test exercises indefinitely and get Ai scores.',
    ToOpen: 'To open',
    free: 'Free',
    Practiced: 'Practiced',
    again: 'Try again',
    model: 'Start',
    submitted: 'Model test submitted',
    ratings: 'View ratings',
    ClosePage: 'Close page',
    Free: 'Free model test analysis',
    watch:
      'The first mock is free by adding a small assistant WeChat to scan code. Help you locate problems, formulate learning plans and improve your scores efficiently.',
    Small: 'Small assistant micro signal',
    got: 'I got it!'
  },
  Word: {
    title: 'My word book',
    modify: 'Modify',
    word: 'Word',
    Mastered: 'Mastered',
    learn: 'To learn',
    rename: 'Rename',
    delete: 'Delete',
    library: 'Word library',
    NewWordbook: 'New Vocabulary',
    cancel: 'Cancel',
    determine: 'Determine',
    default: 'Default',
    return: 'Return',
    spelling: 'View spelling',
    interpretation: 'View interpretation',
    NoInterpretation: 'No interpretation',
    previous: 'The previous',
    next: 'Next',
    correct: 'Correct',
    error: 'Error',
    doIt: 'Try again',
    complete: 'Complete',
    textInterpretation: 'Interpretation',
    InterpretationHidden: 'Interpretation hidden',
    MarkMastery: 'Mark Mastery',
    Submit: 'Submit',
    PlayPronunciation: 'Play pronunciation',
    Operating: 'Operating instructions',
    Add: 'Add word book',
    Correct:
      'Correct spelling will automatically mark that you have mastered it',
    NotLearned: 'Not learned',
    Learned: 'Learned',
    NotMastered: 'Not mastered',
    WordList: 'Word list',
    total: 'Current filter total',
    Words: 'Words',
    clearAll: 'One-click clear master',
    Phonetic: 'Phonetic symbols',
    AddWew: 'Add a new word book',
    toHome: 'Return to home page',
    MyFavorite: 'My Favorite',
    UpTo: 'Up to 6 words',
    AddGroup: 'Add group',
    Favorites: 'Favorites',
    NoTranslation: 'No translation',
    Uptowords: 'Up to 8 words',
    Addto: 'Add to Vocabulary',
    renameTitle: 'Rename',
    deleteTitle: 'Delete',
    delTip: 'Deletion will clear all favorites. Are you sure to delete?',
    defaultSorting: 'Default sorting',
    randomSort: 'Random sorting'
  },

  Forum: {
    PictureUpload: 'Picture upload',
    Enter: 'Enter directly in the text box',
    Enter2: "Title ID + 'spacebar'",
    Enter3: 'Link directly to the topic',
    release: 'Release',
    reply: 'Reply',
    teacher: 'Teacher',
    data: "This week's machine economic data",
    data2: 'Updated this week',
    data3: 'Total high frequency',
    data4: 'Total number of question banks',
    data5: 'Success rate',
    title: 'Join the monthly punch in group',
    title2: 'Small assistant micro signal',
    title3: 'Practice dynamics',
    common: 'Common',
    Click: 'Click to view'
  },
  Topbar: {
    vipText: 'Free 30 day membership',
    vip2: 'Your members have',
    vip3: 'Days due',
    vip4: 'Expiration reminder',
    vip5: 'VIP',
    Scan: 'Scan code and download ptego app',
    Favorites: 'Favorites',
    notification: 'Notification',
    personal: 'Personal data',
    out: 'Are you sure you want to exit',
    sign: 'Sign out',
    cancel: 'Cancel'
  },
  Vip: {
    title: 'Membership and counting',
    surplus: 'Member surplus',
    day: 'Day',
    text: "You haven't opened VIP yet",
    text2: 'Counting balance',
    text3: 'Usage record',
    text4: 'Do you have an exchange code?',
    text5: 'To exchange',
    text6:
      'Receive members, unlimited access to high-frequency machine manual video explanation, and participate in member live classes and card playing practice groups for free every month',
    text7:
      'Add wechat assistant to upgrade, receive 30 day VIP and sprint with more partners',
    text8: 'Free 30 day membership',
    text9: 'Buy member',
    text10: 'Most popular',
    text11: 'Counting coupons',
    purchase: 'Purchase',
    text12: 'Purchase coupons',
    each: 'Each',
    Membership: 'Membership interests',
    Equity: 'Equity comparison',
    common: 'Common problem',
    Usage: 'Usage record',
    theme: 'Theme',
    purpose: 'Purpose',
    Consumption: 'Consumption points',
    Remaining: 'Remaining points',
    Operation: 'Operation time',
    order: 'Order number',
    OrderNmae: 'Order name',
    OrderAmount: 'Order amount',
    Payment: 'Payment method',
    Transaction: 'Transaction status',
    Completion: 'Completion time',
    No: 'No more',
    openVip: 'Open membership',
    credit: 'Point',
    wechatPay: 'WeChat Pay',
    aliPay: 'Alipay',
    qrcodeHelp: 'Scan code to add PTEGO small feedback assistant problem',
    payAgain: 'Pay again',
    openNewPay: 'Please complete the payment on the newly opened page',
    payError: 'Encountered a problem',
    paySuccess: 'Payment successful',
    jumpAlipay: 'about to jump to Alipay platform to complete payment',
    goToPay: 'Go to pay',
    noAutoPay: 'No discount will be automatically renewed',
    openPhoneWechat: 'Open phone Wechat',
    qrcodePay: 'Scan to complete the payment',
    payTimeout: 'Payment timeout',
    tipTitle: 'tip',
    payFail: 'Payment failed',
    codeIpt: 'Enter the redemption code',
    codeIptTip: 'Enter the 8-digit redemption code',
    codeError: 'Invalid redemption code',
    codeActived: 'Activate',
    codeSuccess: 'Exchange successful',
    creditUse: 'Points can be used for',
    creditUseTitle: 'Instructions for use:',
    creditText1:
      'Use coupons to ask questions under different question types will consume points ranging from 2 to 20 points. You will get a confirmation prompt when asking questions. ',
    creditText2:
      'Point coupons never expire after purchase, the number of points after purchase will be accumulated and superimposed, consumption and recharge records can be queried in the usage record. ',
    creditOk: 'Ok',
    memberTitle: 'Receive member',
    memeberTip:
      'WeChat scan code to add PTEGO assistant, get 30-day membership for free',
    memberWechat: 'Wechat FeifanEnglish006'
  },
  User: {
    home: 'Home page',
    personal: 'Personal data',
    settings: 'Personal settings',
    change: 'Change the avatar',
    Nickname: 'Nickname',
    Modify: 'Modify nickname',
    copy: 'Copy',
    copyTip: 'Succeeded in copying the UID.',
    phone: 'Cell-phone number',
    Verified: 'Verified',
    Replace: 'Replace mobile phone',
    password: 'Password',
    changePassword: 'Change Password',
    time: 'Time zone',
    setTime: 'Set time zone',
    Select: 'Select country',
    region: 'Select region',
    Old: 'Old password',
    Please1: 'Please enter your old password',
    New: 'New password',
    Please2: 'Please enter a new password',
    Enter: 'Enter again',
    complete: 'Complete',
    ChangeYourPhone: 'Change your mobile phone',
    Authentication: 'Authentication',
    Binding: 'Binding mobile phone',
    succeeded: 'Binding succeeded',
    Verification: 'Verification code sent to',
    code: 'Enter verification code',
    VerificationError: 'Verification code error',
    next: 'Next step',
    NewMobile: 'New mobile number',
    please3: 'Please enter your mobile phone number',
    select: 'Please select',
    VerificationCode: 'Verification Code',
    please4: 'Please enter the verification code',
    VerificationCodeError: 'Verification code error',
    Replacement: 'Replacement succeeded'
  },

  dashboard: {
    btn: {
      play: 'Play',
      pause: 'Pause',
      fullscreen: 'Fullscreen',
      exitFullscreen: 'Exit full screen',
      mute: 'mute',
      unmute: 'Unmute',
      back: 'Return',
      pip: 'Picture in Picture Mode'
    },
    settings: {
      autoplay: 'Autoplay',
      loop: 'Loop play',
      speed: 'Play speed',
      resolution: 'resolution'
    }
  },
  layers: {
    error: {
      title: '(O_O)? Play error!',
      '2404': 'Cannot find playing video',
      '2502': 'Playing network abnormal',
      '2503': 'Video cannot be decoded',
      '2504': 'Video cannot be played normally'
    },
    loading: {
      msg: 'Loading...'
    }
  },
  state: 'en',
  m: {
    edit: 'Edit',
    add: 'Add',
    time: 'time',
    examinationRecall: 'Contribute Real Questions',
    examinationMessage: 'New Test Recall',
    addExaminationRecall: 'Add Examination Recall',
    questionTypes: ' Question Types',
    state: 'State',
    city: 'City',
    checkPoints: 'Test Centers',
    headline: 'headline',
    query: 'Query',
    notRecordNow: 'No Record Now',
    message: 'Message',
    classInformation: 'Class Information',
    assignmentList: 'Assignments',
    myAssignment: 'MyAssignment',
    teacherReply: 'Teacher Reply',
    fileName: 'File Name',
    download: 'Download',
    loading: 'Loading',
    noMore: 'This Is The End',
    recallPoint: 'Keypoints',
    seeMore: 'See More',
    view: 'View',
    publicTime: 'Public Time',
    cutOffTime: 'Due',
    finished: 'Finished',
    unfinished: 'Unfinished',
    ToDoTheTopic: 'Do The Assignments',
    systemMessageServeOnlyAppSeeDetails: 'System Message Open In PTE GO App',
    AppFunctionStrongerPleaseOpenAppSeeMore: 'There Is More In PTE GO App',
    betterUserExperienceInFeifanApp: 'Better user experience in Feifan app',
    replyForVoice: 'Voice Reply',
    replyQuestion: 'Original question:',
    replyClass: 'From the class:',
    allReadTitle:
      'Practice interface using "question" after receiving the teacher\'s response',
    allReadBtn: 'All marked as read',
    teacher: 'Teacher',
    allReadClassTitle:
      'Homework issue notice, prompt notice, and teacher correction notice to homework',
    nowSearchConut: 'Matched Entries',
    rowInformation: 'Message',
    downloadMaterials: 'Download',
    search: 'Search',
    whole: 'All',
    enhanceSkill: 'Enhance Skill',
    collect: 'Save',
    isCollect: 'Saved',
    collectSuccess: 'Successfully Saved',
    collectCancel: 'Cancelled',
    cancel: 'Cancel',
    introduceCourse: 'Course Info',
    courseContent: 'Course Content',
    courseCatalog: 'CourseSchedule',
    total: 'Total',
    classHour: 'Course Hours',
    startPracticing: 'Start Practicing',
    teacherExplained: 'Detailed Explanation:',
    teacherExample: 'Tutor Demo',
    practiceRequirements: 'Practice Requirements',
    myPractice: 'My Practice',
    errorTopic: 'Wrong Answers',
    soundmark: 'English',
    peopleTime: 'Times',
    peopleFinished: 'People Finished',
    doHomework: 'Do Assignments',
    filtrateByTime: 'Filter By Time',
    filtrateByTeacher: 'Filter By Tutor',
    periodOfValidity: 'Valid Till',
    renew: 'Resubscribe',
    activate: 'Activate',
    packUp: 'Collapse',
    expand: 'Expand',
    joinClass: 'Join Class',
    PracticeTopic: 'Practices',
    learningDays: 'Days Studying PTE',
    exerciseBook: 'Assignments',
    vocab: 'Vocabulary',
    rightsAndInterests: 'VIP Rights',
    exclusiveTeacherOnlineToAnswerQuestions: 'Real Tutor Q&A',
    rangeOfApplicationValues: 'Range Of Application Values',
    directionForUse: 'Direction Of Use >',
    goToRenew: 'Get More Credits',
    useRecall: 'Credits History',
    aTotalOfUsing: 'Total Used',
    minute: 'Minute',
    recall: 'Record',
    eachQuestionTakesMinutes: 'Minutes Per Question',
    getDirection: 'How To Get',
    type: 'Type',
    presentMinutes: 'Free Minutes',
    purchaseSeparately: 'Purchase Separately',
    goToBuy: 'Purchase',
    inPreparationComingSoon: 'Coming Soon',
    noData: 'Nothing Here',
    noQuestion: 'Empty',
    loadingForYou: 'Loading',
    loadingRecord: 'recording, please wait...',
    wechatdownload: 'Wechat code scanning Download',
    tip: 'For a better user experience, Google Chrome is recommended',
    answer: 'answer',
    seeOrgin: 'View original question',
    againAnswer: 'retry',
    seeEvaluate: 'View overall comments',
    deleteNotify: 'Delete the notification'
  },
  placeholder: {
    select: 'Select',
    pleaseSelect: 'Please Select',
    pleaseFallIn: 'Please typing',
    selectTime: 'Select Time',
    pleaseFallInHeadline: 'Please Put Title Here',
    selectQuestionTypes: 'Select Question Types',
    inputActivationCode: 'Input Activation Code',
    pleaseInput8VipActivationCode: 'Please Input 8 Digit Activation Code',
    pleaseDescribeYourProblemInDetail: 'Please Describe Your Problem In Detail',
    clickHereToAnswer: 'click here to answer',
    addExamination: 'Add Real Questions From Your Test（More Than 5 Words）'
  },
  inquiry: {},
  menu: {
    home: 'Home',
    VIP: 'VIP',
    search: 'Search',
    message: 'Message',
    teacherMessage: 'Tutor Message',
    classMessage: 'Class Message',
    systemMessage: 'System Notification',
    gre: 'Question Bank',
    listen: 'Listening',
    speak: 'Speaking',
    word: 'Word',
    read: 'Reading',
    write: 'Writing',
    grePractice: 'Practice',
    examinationList: 'Contribute Real Questions',
    skill: 'Enabling Skills',
    wordBook: 'Vocabulary',
    dataShare: 'Downloads',
    personal: 'My',
    logOut: 'Log Out',
    reset: 'Reset',
    exercises: 'Practice',
    mockExam: 'Mock Test',
    forum: 'Circle'
  },
  gre: {
    all: 'All',
    recurring: 'High Frequency',
    gre: 'Question Bank',
    questions: 'Mock Questions',
    new: 'New',
    hot: 'Hot',
    practice: 'Practice',
    unread: 'Unread',
    grePractice: 'Practice',
    transcriptAnswerKeys: 'Transcript/Answer Keys ',
    expertDemonstration: 'Tutor Demo',
    countdown: 'Countdown',
    transcri: 'Transcrit',
    answerKeys: 'Answer Keys',
    save: 'Save',
    saved: 'Saved',
    getHelp: 'Get help',
    getHelpTip: 'Spend ',
    getHelpTip2: ' Minutes Credit For This Question',
    detailedExplanation: 'Detailed Explanation',
    submit: 'Submit',
    submits: 'Submit',
    myPractice: 'My Practice',
    wordcount: 'Word count',
    stress: 'Stress',
    pausing: 'Pausing',
    answerTitle: 'Original question:',
    answerContent: 'The original content:',
    translate: 'Translation',
    dragTip: 'Drag To Re Order',
    recognitionRate: 'Recognition Rate',
    practiceHistory: 'Practice History',
    noThing: 'No Records',
    point: 'Point',
    confirmThisIsARealExamQuestion: 'Confirme',
    confirmThisIsARealExamQuestionEd: 'Confirmed',
    keypoints: 'Key Points',
    referenceAnswer: 'Answer Keys',
    moreInPTEGOAPP: 'More Info On PTE GO App',
    nextknowledgePoint: 'Next knowledge Point'
  },
  navTitle: {
    VIP: 'VIP',
    Search: 'Search',
    Information: 'Message',
    GreIndex: 'QuestionBank',
    GrePracticeIndex: 'Practice',
    GreListen: 'Listening',
    GreSpeak: 'Speaking',
    GreRead: 'Reading',
    GreWrite: 'Writing',
    GrePracticeListen: 'Practice Listening',
    GrePracticeSpeak: 'Practice Speaking',
    GrePracticeRead: 'Practice Reading',
    GrePracticeWrite: 'Practice Writing',
    GreDetail: 'Question Bank',
    Examination: 'Examination',
    Skill: 'Skill',
    wordBook: 'Vocabulary',
    SkillDetails: 'Enabling Skills',
    SkillExercise: 'Enabling Skills',
    DataShare: 'Downloads',
    ExaminationList: 'Contribute Real Questions',
    ExerciseBookList: 'Vocabulary',
    UserCenterExerciseBookDetails: 'Do Assignments',
    SkillExerciseTask: 'Do Assignments',
    UserCenterExerciseBookDetailsTwo: 'Do Assignments',
    DictionaryList: 'Vocab',
    ExamMemoryList: 'Contribute',
    CollectList: 'Saved',
    InformationTeacherMessage: 'Tutor Messagee',
    InformationClassMessage: 'Class Message',
    InformationTeacherMessageDetails: 'Tutor Message',
    InformationClassMessageDetails: 'Assignments',
    InformationSystem: 'System Message',
    GrePracticeDetail: 'Practice',
    AddExamination: 'Contribute Real Questionsn'
  },
  search: {
    tip: 'Topic /ID/ Original recording',
    all: 'All',
    speak: 'Speak',
    write: 'Write',
    read: 'Read',
    listen: 'Listen'
  },
  login: {
    login: 'Login',
    mobile: 'Mobile phone number',
    password: 'Password',
    passwordTip: 'Please enter your password',
    otherLogin: 'Other login methods',
    register: 'Register an account',
    aboutMe: 'About Us',
    privacy: 'Privacy Agreement',
    terms: 'User Policy',
    forgetPwd: 'Forgot password',
    mobileTip: 'Please enter your mobile phone number',
    smsCode: 'Verification code',
    sendSmsCode: 'Send verification code',
    reSendSmsCode: 'Send again',
    smsCodeTip: 'Please enter your verification code',
    newPwd: 'New password',
    newPwdTip: 'Please enter a new password',
    backLogin: 'Return to login',
    confirm: 'Confirm',
    registerTitle: 'Register',
    agreement: 'Click "register" to signify your agreement',
    and: 'and',
    selectTip: 'Please select',
    sendSmsSuccess: 'Send verification code successfully',
    mobileError: 'Mobile phone number format error',
    registerSuccessTip: 'Registered successfully, jumping...'
  }
}

export default en
