//ch.js
const ch = {
  Home: {
    title: '新的一天，继续向目标努力吧！',
    manText: '课程与班级',
    userStat: '目标分数',
    distance: '距考试',
    day: '天',
    practice: '练习',
    today: '今日已练',
    topic: '题',
    topicLibrary: '题库',
    navStudyData: '查看学习数据',
    totalText: '累计学习',
    study: '已连续学习',
    highest: '最高记录',
    tested: '最近已考出',
    branch: '分',
    process: '在学习过程中共获得了',
    individual: '个',
    member: '会员',
    openClass: '公开课',
    ClassClass: '班级课',
    Favorites: '收藏夹',
    whole: '全部',
    DataDownload: '文件下载',
    MachineUpdate: '机经更新',
    updated: '已更新',
    Totalbanks: '题库总数',
    TotalFrequency: '高频总数',
    Updated: '本周更新',
    SuccessRate: '中题率',
    exerciseBook: '作业本',
    teacher: '老师',
    already: '已有',
    completion: '人完成',
    Release: '发布时间',
    deadline: '截止时间',
    NoRecord: '暂无记录',
    ToCompleted: '待完成',
    Home: '返回首页',
    class: '筛选班级',
    see: '查看',
    finish: '去完成',
    JobList: '作业列表',
    JobEvaluation: '作业评价',
    Overall: '作业整体评价',
    Recent: '近期高频预测',
    Download: '备考资料下载',
    word: '单词',
    Phonetic: '音标',
    interpretation: '释义',
    objectives: '考试目标',
    zha: '炸',
    divide: '均分',
    speak: '口语',
    write: '写作',
    read: '阅读',
    listen: '听力',
    addScore: '添加小分',
    examDate: '考试日期',
    choiceDate: '选择日期',
    oldScore: '历史成绩',
    sure: '确 定',
    settingSuccess: '设置成功',
    scoreRange: '分数范围10-90',
    noDigit: '不支持小数',
    setting: '设置'
  },
  Pra: {
    title: '已选目标分必练题型',
    below: '35分及以下',
    points: '分',
    topPoints: '79分及以上',
    MustPractice: '必练',
    Success: '中题率',
    Return: '返回作业列表',
    Return2: '返回课程',
    current: '当前',
    topic: '题',
    common: '共',
    Check: '查看原题',
    Return3: '返回搜索结果列表',
    ReturnMessage: '返回通知消息',
    Return4: '返回收藏夹',
    title2: '官方题型讲解、答题技巧说明',
    Selected: '查看列表',
    Practice: '练习进度',
    More: '更多筛选条件',
    Tips: '小贴士',
    filter: ' 根据当前条件，已筛选出',
    Submit: '提交',
    shiFan: '老师示范',
    subtitle: '字幕',
    yes: '考过',
    Collection: '收藏',
    error: '报错',
    share: '分享',
    tiWen: '提问',
    vido: '视频讲解',
    redo: '重做',
    Last: '上一题',
    Next: '下一题',
    add: '添加到收藏夹',
    text: '最多6个字',
    text2: '添加分组',
    confirm: '确定',
    tips: '收藏保存成功',
    AI: '今日AI评分剩余',
    second: '次',
    Fill: '填写考过信息',
    text3:
      '您的考过信息及回忆将被整理收录，并帮助大家获得更精准的高频排序及预测。',
    test4: '考试日期',
    Required: '必填',
    Please: '请选择年份',
    text5: '选择年份(限近两年内)',
    text6: '请选择月份',
    text7: '考场',
    text8: '上次选择：',
    text9: '选择国家',
    text10: '选择城市',
    text11: '选择考场',
    memory: '回忆',
    determine: '确 定',
    text12: '开启麦克风权限',
    text13: '按下图所示，点击“始终允许XX使用您的麦克风”。',
    text14: '为了达到最佳体验，推荐您使用最新版本的谷歌浏览器。',
    text15: '您的麦克风权限未开启，开启后才能正常录音。',
    text16: '如何开启？',
    text17: '开通会员',
    text18:
      '您今日的AI评分次数已达上限。开通PTEGO会员，享受无限使用AI评分功能特权。',
    text19: '去开通',
    text20: 'AI评分',
    text21: 'AI评分约需要20-40秒',
    text22: '可以关闭此页面，稍后再查看哦',
    text23: '评分项',
    text24: '得分(1)',
    text25: '得分点评',
    text26: '官方标准',
    text27: '内容分',
    text28: '单词正确',
    text29: '单词不正确、发音太差、发音未被识别',
    text30: '总分',
    text31: '我的练习：',
    text32: '评分项',
    text33: '得分(90)',
    text34: '得分点评',
    fluency: '流利度',
    text35: '发音分',
    text36: '内容分',
    text37: '详情查看下方智能识别结果。',
    text38: '总分',
    text39: '我的练习：',
    text40: '智能识别结果',
    text41: '优',
    good: '良',
    difference: '差',
    text42: '(漏读)',
    text43: '多读',
    text44: '再录一次',
    text45: '查看AI评分',
    text46: '正在努力评分中。请稍后查看',
    text47: '评分中',
    text48: '开始AI评分',
    text49: '请先提交练习录音',
    text50: '查看智能识别',
    text51: '识别中...',
    answer: '答案',
    score: '得分',
    text52: '正确率',
    analysis: '解析',
    text53: '意群',
    text54: '为意群分割提示',
    text55: '查看意群使用指南',
    stress: '重音',
    text56: '为重音位置提示',
    text57: '查看重音使用指南',
    text58: '为意群分割位置',
    text59: '为断句换气位置',
    text60: '查看断句使用指南',
    pause: '断句',
    text61: '主旨',
    translate: '翻译',
    text62: '断句的使用',
    text63: '视频讲解',
    text64: '文字讲解',
    text65: '什么是断句功能？',
    text66:
      '当练习界面右下角“断句”功能开启时，同学们会看到文本中出现了两种符号：',
    text67: '第一种为绿色向下的小箭头，代表着意群的划分位置；',
    text68: '第二种为红色的双斜线，代表着句子应该有的停顿及换气位置。',
    Ventilate: '换气',
    text69: '意群及换气位置的正确表达，关系着PTE口语中对流利度的评分。',
    text70: '如何练习断句？',
    text71: ' 将2-3个单词组合成短语，进行连读练习。',
    text72: '找到意群分割位置，练习意群连接。',
    text73: '确认文本中换气位置，结合意群朗读。',
    text74:
      '注意：仅在“//”处做出停顿并进行换气，“↘”处无需做停顿，每个意群中要注意重读、弱读、连读的处理。',
    text75: '更具体的举例说明，请大家移步观看断句功能讲解视频中的示范。',
    text76: '重音的使用',
    text77: '什么是重音功能？',
    text78:
      '当练习界面右下角“重音”功能开启时，同学们会看到文本的上方出现了一些红色的原点，原点所在的位置即表示重音所在的位置。',
    example: '例',
    text79: '单词重音及句子重音的正确表达，是PTE口语的关键考察点。',
    text80: '如何在练习中体现单词重音？是在有重音标注的音节上使劲儿读吗？',
    text81:
      '单词重音并不是通过在有重音的音节上使劲儿读得到的，而是有重音的音节正常度，其他音节则弱读。通过这种反差，让有重音的音节听起来是比其他音节要重。',
    text82: '例如',
    text83:
      '例如这个单词重音位置在中间，先找到一个正常音量的MU，再弱读其他音节，即可得到一个重音正确的community发音。',
    text84: '什么是句子重音？如何做到句子重音？',
    text85:
      '在读一个句子时，我们并不会强调每一个单词，而是强调那些有实际意义的词汇（内容词）。对于没有实际意义的词汇（功能词），则需要我们弱读。',
    text86: '做到功能词的弱读和连读',
    text87:
      ' 拥有句子重音的关键是功能词的弱读（元音弱化 vowel reduction）和连读，与单词重音一样，在句子重音中要避免‘使劲儿’的发音习惯。在读内容词时用正常说话的声音即可，通过功能词的弱读和连读来让内容词听起来是有重音的。',
    text89: '做到意群连读',
    text90:
      '将句子划分为若干个意群，所谓意群就是指句子中按意思和结构划分出的各个成分，每一个成分即称为一个意群。同一意群中的词与词关系紧密，不能随意拆分，否则就会引起误解。意群结束处的词汇要做到尾音落地，通过意群连读带出句子结构并突出句子重音。',
    text91: '更具体的举例说明，请大家移步观看重音功能讲解视频中的示范。',
    text92: 'AI智能识别',
    text93: '题目原文',
    text94: '我的练习：',
    text95: '智能识别结果',
    text96: '正确',
    Misreading: '错读',
    text97: '未检测到语音',
    text98: '请选择问题类型',
    text99: '请具体描述问题',
    text100: '向老师提问',
    text101: '选择你想要提交的音频',
    lately: '最近',
    PracticeAudio: '次练习音频',
    NoAudio: '暂无音频记录',
    text102: '请先提交练习，再提问哦',
    text103: '描述你的问题',
    text104: '本次提问将消耗',
    text105: '点券',
    text106: '请在此描述问题；提问前可翻阅历史，避免重复～',
    text107: '正在努力评分中。请稍后查看',
    Folded: '已折叠',
    Records: '条记录',
    text108: '跟大家一起讨论吧',
    publish: '发表',
    discuss: '讨论',
    text109: '该讨论已删除',
    reply: '回复',
    text110: '本次练习将提交给老师批改',
    Yours: '你的',
    text111: '最新一次练习记录',
    text112: '将会提交给老师批改，建议反复练习至最佳状态。',
    bx: '练习表现',
    Average: '平均正确率',
    everybody: '大家',
    my: '我的',
    text113: '本题平均正确率',
    text114: '近期正确率',
    text115: '目标正确率',
    text116: '数据不足',
    text117: '在同学们使用',
    text118: '提问',
    text119:
      '"功能后，老师会在24小时内回复，回复内容会显示在这里。提问功能需要消耗"',
    text120: '点券可在会员中心购买',
    text121: '题型讲解',
    text122: '已选题目',
    text123: '题库共',
    text124: '题，已筛选出',
    text125: '，筛选条件有：',
    text126: '详解'
  },
  Mock: {
    title: '我的记录',
    paper: '模考试卷',
    name: '模考名称',
    progress: '进度',
    Creation: '创建时间',
    operation: '操作',
    Completed: '已完成',
    score: '已评分',
    see: '查看',
    continue: '继续',
    delete: '删除',
    There: '暂无历史模考记录',
    vipTips:
      'PTEGO不仅有单题型练习模考及迷你小模考，更有每月根据高频更新而持续更新的全仿真计时模考。开通会员即可无限使用以上模考练习，并获得AI评分。',
    ToOpen: '去开通',
    free: '免费',
    Practiced: '练过',
    again: '再做一次',
    model: '去模考',
    submitted: '模考已提交',
    ratings: '查看评分',
    ClosePage: '关闭页面',
    Free: '免费模考分析',
    watch:
      '扫码添加小助手微信，可获得一次免费一对一模考分析。帮助你定位问题，制定学习方案，高效提分。',
    Small: '小助手微信号',
    got: '我知道了!'
  },

  Word: {
    title: '我的单词本',
    modify: '修改',
    word: '单词',
    Mastered: '已掌握',
    learn: '去学习',
    rename: '重命名',
    delete: '删除',
    library: '单词库',
    NewWordbook: 'New Vocabulary',
    cancel: '取消',
    determine: '确定',
    default: '默认',
    return: '返回',
    spelling: '查看拼写',
    interpretation: '查看释义',
    NoInterpretation: '暂无释义',
    previous: '上一个',
    next: '下一个',
    correct: '正确',
    error: '错误',
    doIt: '再试一次',
    complete: '完成',
    textInterpretation: '释义',
    InterpretationHidden: '释义已隐藏',
    MarkMastery: '标记掌握',
    Submit: '提交',
    PlayPronunciation: '播放读音',
    Operating: '操作说明',
    Add: '加入单词本',
    Correct: '拼写正确会自动标记已掌握哦',
    NotLearned: '个未学习',
    Learned: '个已学习',
    NotMastered: '个未掌握',
    WordList: '单词列表',
    total: '当前筛选共',
    Words: '词',
    clearAll: '一键清除已掌握',
    Phonetic: '音标',
    AddWew: '加入生词本',
    mastered: '已掌握',
    toHome: '返回首页',
    MyFavorite: '我的收藏夹',
    UpTo: '最多6个字',
    AddGroup: '添加分组',
    Favorites: '收藏夹',
    NoTranslation: '暂无翻译',
    Uptowords: '最多8个字',
    Addto: '添加到单词本',
    renameTitle: '重命名',
    deleteTitle: '删除',
    delTip: '删除将会清空所有收藏，确认删除吗？',
    defaultSorting: '默认排序',
    randomSort: '随机排序'
  },
  Forum: {
    PictureUpload: '图片上传',
    Enter: '文本框内直接输入',
    Enter2: "题目ID+'空格键'",
    Enter3: '直接链接到该题目哦',
    release: '发布',
    reply: '回复',
    teacher: '老师',
    data: '本周机经数据',
    data2: '本周更新',
    data3: '高频总数',
    data4: '题库总数',
    data5: '中题率',
    title: '加入每月打卡团',
    title2: '小助手微信号',
    title3: '练习动态',
    common: '共',
    Click: '点击查看'
  },
  Topbar: {
    vipText: '免费领30天会员',
    vip2: '您的会员还有',
    vip3: '天到期',
    vip4: '到期提醒',
    vip5: '会员中心',
    Scan: '扫码下载PTEGO APP',
    Favorites: '收藏夹',
    notification: '通知中心',
    personal: '个人资料',
    out: '是否确认退出',
    sign: '退出',
    cancel: '取消'
  },

  Vip: {
    title: '会员及点券',
    surplus: '会员剩余',
    day: '天',
    text: '您还未开通VIP',
    text2: '点券余额',
    text3: '使用记录',
    text4: '已有兑换码？',
    text5: '去兑换',
    text6:
      '领取会员，无限查看高频机经视频讲解，每月免费参与会员直播课及打卡带练团',
    text7: '添加微信小助手升级领取30天VIP，和更多小伙伴一同冲刺',
    text8: '免费领30天会员',
    text9: '购买会员',
    text10: '最受欢迎',
    text11: '点券',
    purchase: '购买',
    text12: '购买点券',
    each: '每',
    Membership: '会员权益',
    Equity: '权益对比',
    common: '常见问题',
    Usage: '使用记录',
    theme: '主题',
    purpose: '用途',
    Consumption: '消耗点数',
    Remaining: '剩余点数',
    Operation: '操作时间',
    order: '订单号',
    OrderNmae: '订单名称',
    OrderAmount: '订单金额',
    Payment: '支付方式',
    Transaction: '交易状态',
    Completion: '完成时间',
    No: '没有更多了',
    openVip: '开通会员',
    credit: '点券',
    wechatPay: '微信支付',
    aliPay: '支付宝',
    qrcodeHelp: '扫码添加PTEGO小助手反馈问题',
    payAgain: '再次支付',
    openNewPay: '请在新打开的页面完成付款',
    payError: '支付遇到问题',
    paySuccess: '支付成功',
    jumpAlipay: '即将跳转支付宝平台完成支付',
    goToPay: '去支付',
    noAutoPay: '到期不会自动续费',
    openPhoneWechat: '打开手机微信',
    qrcodePay: '扫一扫完成支付',
    payTimeout: '付款超时',
    tipTitle: '提示',
    payFail: '支付失败',
    codeIpt: '输入兑换码',
    codeIptTip: '输入8位兑换码',
    codeError: '兑换码无效',
    codeActived: '激活',
    codeSuccess: '兑换成功',
    creditUse: '点券可用于',
    creditUseTitle: '使用说明：',
    creditText1:
      '使用点券提问在不同题型下提问将消耗点券数2到20不等，您会在提问时得到确认提示。',
    creditText2:
      '点券购买后永不过期，购买后的点券数会累积叠加，消耗及充值记录均可在使用记录中查询。',
    creditOk: '好的',
    memberTitle: '领取会员',
    memeberTip: '微信扫码添加PTEGO小助手，免费获取30天会员',
    memberWechat: '微信号FeifanEnglish006'
  },
  User: {
    home: '首页',
    personal: '个人资料',
    settings: '个人设置',
    change: '更换头像',
    Nickname: '昵称',
    Modify: '修改昵称',
    copy: '复制',
    copyTip: '复制UID成功',
    phone: '手机号',
    Verified: '已验证',
    Replace: '更换手机',
    password: '密码',
    changePassword: '修改密码',
    time: '时区',
    setTime: '设定时区',
    Select: '选择国家',
    region: '选择地区',
    Old: '旧密码',
    Please1: '请输入旧密码',
    New: '新密码',
    Please2: '请输入新密码',
    Enter: '再次输入',
    complete: '完成',
    ChangeYourPhone: '换绑手机',
    Authentication: '验证身份',
    Binding: '绑定手机',
    succeeded: '绑定成功',
    Verification: '验证码已发送至',
    code: '输入验证码',
    VerificationError: '验证码错误',
    next: '下一步',
    NewMobile: '新手机号',
    please3: '请输入你的手机号码',
    select: '请选择',
    VerificationCode: '验证码',
    please4: '请输入验证码',
    VerificationCodeError: '验证码错误',
    Replacement: '更换成功'
  },

  dashboard: {
    btn: {
      play: '播放',
      pause: '暂停',
      fullscreen: '全屏',
      exitFullscreen: '退出全屏',
      mute: '静音',
      unmute: '取消静音',
      back: '返回',
      pip: '画中画模式'
    },
    settings: {
      autoplay: '自动播放',
      loop: '循环播放',
      speed: '播放速度',
      resolution: '清晰度'
    }
  },
  layers: {
    error: {
      title: '(O_O)?  播放错误!',
      '2404': '找不到播放视频',
      '2502': '播放网络异常',
      '2503': '视频无法解码',
      '2504': '视频无法正常播放'
    },
    loading: {
      msg: '加载中 ...'
    }
  },

  state: 'ch',
  m: {
    edit: '编辑',
    add: '新增',
    time: '时间',
    examinationRecall: '考场回忆',
    addExaminationRecall: '新增考场回忆',
    examinationMessage: '考场信息',
    questionTypes: '题型',
    state: '国家',
    city: '城市',
    checkPoints: '考点',
    headline: '标题',
    query: '查询',
    notRecordNow: '暂无记录',
    message: '消息',
    classInformation: '班级通知',
    assignmentList: '作业列表',
    myAssignment: '我的作业',
    teacherReply: '老师回复',
    fileName: '文件名',
    download: '下载',
    loading: '加载中',
    noMore: '没有更多了',
    recallPoint: '回忆要点',
    seeMore: '查看更多',
    view: '查看详情',
    publicTime: '发布时间',
    cutOffTime: '截止时间',
    finished: '已完成',
    unfinished: '未完成',
    ToDoTheTopic: '去做题',
    systemMessageServeOnlyAppSeeDetails: '系统消息服务仅支持App查看详情',
    AppFunctionStrongerPleaseOpenAppSeeMore:
      'App内功能更强大，请打开App端进行查看',
    betterUserExperienceInFeifanApp: '更好的用户体验 尽在飞凡APP',
    replyForVoice: '语音回复',
    replyQuestion: '提问原题:',
    replyClass: '来自班级:',
    allReadTitle: '练习界面使用“提问”后收到的老师回复',
    allReadBtn: '全部标为已读',
    teacher: '老师',
    allReadClassTitle: '作业发布通知、催交通知，及老师对作业的批改通知',
    nowSearchConut: '当前共搜索出',
    rowInformation: '条信息',
    downloadMaterials: '下载资料',
    search: '搜索',
    whole: '全部',
    enhanceSkill: '技巧增强',
    collect: '收藏',
    isCollect: '已收藏',
    collectSuccess: '收藏成功',
    collectCancel: '已取消收藏',
    cancel: '取消',
    introduceCourse: '课程介绍',
    courseContent: '课程内容',
    courseCatalog: '课程目录',
    total: '共',
    classHour: '课时',
    startPracticing: '开始练习',
    teacherExplained: '老师讲解',
    teacherExample: '老师示范',
    practiceRequirements: '练习要求',
    myPractice: '我的练习',
    errorTopic: '错题',
    soundmark: '英',
    peopleTime: '人次',
    peopleFinished: '人已完成',
    doHomework: '去做作业',
    filtrateByTime: '按时间筛选',
    filtrateByTeacher: '按老师筛选',
    periodOfValidity: '有效期',
    renew: '续费',
    activate: '激活',
    packUp: '收起',
    expand: '展开',
    joinClass: '加入班级',
    PracticeTopic: '练习题目',
    learningDays: '学习天数',
    exerciseBook: '作业本',
    vocab: '生词本',
    rightsAndInterests: 'VIP权益',
    exclusiveTeacherOnlineToAnswerQuestions: '专属老师线上答疑',
    rangeOfApplicationValues:
      '使用范围：所有机经、练习中点击“向老师提问”即可使用',
    directionForUse: '使用说明',
    goToRenew: '去充值',
    useRecall: '使用记录',
    aTotalOfUsing: '共使用',
    minute: '分钟',
    recall: '记录',
    eachQuestionTakesMinutes: '每次提问消费分钟',
    getDirection: '获取说明',
    type: '类型',
    presentMinutes: '赠送分钟',
    purchaseSeparately: '单独购买',
    goToBuy: '前往购买',
    inPreparationComingSoon: '正在准备中,敬请期待',
    noData: '空空如也',
    noQuestion: '暂无题目数据',
    loadingForYou: '正在为您加载中',
    loadingRecord: '正在上传，请稍后...',
    wechatdownload: '微信扫码下载',
    tip: '为了获得更好的用户体验，建议使用谷歌浏览器',
    answer: '作答',
    seeOrgin: '查看原题',
    againAnswer: '再次练习',
    seeEvaluate: '查看整体评价',
    deleteNotify: '删除该通知'
  },
  placeholder: {
    select: '选择',
    pleaseSelect: '请选择',
    pleaseFallIn: '请在此填写',
    selectTime: '选择时间',
    pleaseFallInHeadline: '请在此填写标题',
    selectQuestionTypes: '选择题型',
    inputActivationCode: '输入激活码',
    pleaseInput8VipActivationCode: '请输入8位会员激活码',
    pleaseDescribeYourProblemInDetail: '请详尽描述你的问题',
    clickHereToAnswer: '点击此处进行作答',
    addExamination: '请在这里填写回忆要点(大于5个字)'
  },
  inquiry: {},
  menu: {
    home: '首页',
    gre: '机经',
    listen: '听',
    speak: '说',
    read: '读',
    write: '写',
    grePractice: '练习',
    skill: '课程',
    wordBook: '单词',
    forum: '论坛',
    search: '搜索',
    VIP: 'VIP会员',
    message: '消息',
    teacherMessage: '老师回复',
    classMessage: '班级通知',
    systemMessage: '系统通知',
    examinationList: '考场回忆',
    dataShare: '资料共享',
    personal: '个人中心',
    logOut: '退出登录',
    reset: '重置',
    exercises: '机经练习',
    mockExam: '模考'
  },
  gre: {
    all: '全部',
    recurring: '高频',
    gre: '机经',
    questions: '模拟题',
    new: '最新',
    hot: '高频',
    practice: '练习',
    unread: '未读',
    grePractice: '机经练习',
    transcriptAnswerKeys: '字幕/答案',
    expertDemonstration: '老师示范',
    practiceHistory: '答题记录',
    countdown: '倒计时',
    transcri: '字幕',
    answerKeys: '答案',
    save: '收藏',
    saved: '已收藏',
    getHelp: '向老师提问',
    getHelpTip: '本次提问将消耗',
    getHelpTip2: '点券',
    detailedExplanation: '老师详解',
    submit: '看答案',
    submits: '提交',
    myPractice: '我的练习',
    wordcount: '字数统计',
    stress: '建议重音',
    pausing: '建议断句',
    answerTitle: '提问原题:',
    answerContent: '原题内容:',
    keypoints2: '显示主旨',
    translate: '显示翻译',
    dragTip: '拖拽这些选项进行排序',
    recognitionRate: '识别度，请注意查看标记',
    noThing: '暂无记录',
    point: '分',
    confirmThisIsARealExamQuestion: '确认考过',
    confirmThisIsARealExamQuestionEd: '已确认考过',
    keypoints: '回忆要点',
    referenceAnswer: '参考答案',
    moreInPTEGOAPP: '更多记录请至APP查看',
    nextknowledgePoint: '下一个知识点'
  },
  navTitle: {
    VIP: 'VIP会员',
    Search: '搜索',
    Information: '消息',
    GreIndex: '机经',
    GrePracticeIndex: '机经练习',
    GreListen: '机经-听',
    GreSpeak: '机经-说',
    GreRead: '机经-读',
    GreWrite: '机经-写',
    GrePracticeListen: '机经练习-听',
    GrePracticeSpeak: '机经练习-说',
    GrePracticeRead: '机经练习-读',
    GrePracticeWrite: '机经练习-写',
    GreDetail: '机经',
    Examination: '考场回忆',
    Skill: '技巧增强',
    SkillDetails: '技巧增强',
    SkillExercise: '技巧增强',
    DataShare: '资料共享',
    ExaminationList: '考场回忆',
    SkillExerciseTask: '个人中心-作业本',
    ExerciseBookList: '个人中心-作业本',
    UserCenterExerciseBookDetailsTwo: '个人中心-作业本',
    UserCenterExerciseBookDetails: '个人中心-作业本',
    DictionaryList: '个人中心-生词本',
    ExamMemoryList: '个人中心-考试回忆',
    CollectList: '个人中心-收藏',
    InformationTeacherMessage: '老师回复',
    InformationClassMessage: '班级通知',
    InformationSystem: '系统通知',
    InformationTeacherMessageDetails: '老师聊天记录',
    InformationClassMessageDetails: '作业列表',
    GrePracticeDetail: '机经练习详情',
    AddExamination: '新增考场回忆'
  },
  search: {
    tip: '题目/ID/录音原文',
    all: '全部',
    speak: '口语',
    write: '写作',
    read: '阅读',
    listen: '听力'
  },
  login: {
    login: '登录',
    mobile: '手机号',
    password: '密码',
    passwordTip: '请输入你的密码',
    otherLogin: '其他登录方式',
    register: '注册账户',
    aboutMe: '关于我们',
    privacy: '隐私协议',
    terms: '用户政策',
    forgetPwd: '忘记密码',
    mobileTip: '请输入你的手机号码',
    smsCode: '验证码',
    sendSmsCode: '发送验证码',
    reSendSmsCode: '再次发送',
    smsCodeTip: '请输入你的验证码',
    newPwd: '新密码',
    newPwdTip: '请输入新密码',
    backLogin: '返回登录',
    confirm: '确认',
    registerTitle: '注册',
    agreement: '点击“注册”即表示您同意',
    and: '和',
    selectTip: '请选择',
    sendSmsSuccess: '发送验证码成功',
    mobileError: '手机号码格式错误',
    registerSuccessTip: '注册成功，跳转中...'
  }
}

export default ch
