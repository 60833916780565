import Vue from 'vue'
import Vuex from 'vuex'

import getters from './getters'

import app from './modules/app'
import exam from './modules/exam'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    exam
  },
  getters,
  strict: process.env.ENV !== 'production'
})

store.dispatch('exam/SetTimeType', {})

export default store
